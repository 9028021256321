import React from "react";
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import Overview from '../Components/Management/Overview';

function ManagementPage()
{
    return (
        <div id="upd-main">
            <Header />
            <main>
                <div className="dashboard-wrapper">
                    <Overview />
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default ManagementPage;