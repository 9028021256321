import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import moment from 'moment';
import UserFileService from '../../../Service/UserFileService';
import PartnerService from '../../../Service/PartnerService';
import ErrorHandler from '../../ErrorHandler';

function Overview()
{
    const toast = useRef(null);
    const navigate = useNavigate();
    const [dtoList, setDtoList] = useState(null);
    const [selectedDto, setSelectedDto] = useState(null);
    const [refreshTrigger, setRefreshTrigger] = useState(false);
    const [partnerList, setPartnerList] = useState([]);

    moment.locale('nl');

    useEffect(() =>
    {
        PartnerService.List().then((res) =>
        {
            setPartnerList(res.data);
        });
    }, []);

    useEffect(() =>
    {
        // Retrieve all the items
        UserFileService.List().then((res) =>
        {
            setDtoList(res.data);
        });
    }, [refreshTrigger]);

    // Show error toast, nothing selected
    const showNothingSelected = () => 
    {
        toast.current.show({ severity: 'error', summary: 'Fout', detail: 'Er is geen dossier geselecteerd' });
    }

    // Load edit 
    const openDto = () =>
    {
        if (selectedDto === null)
            showNothingSelected();
        else
            navigate(`./${selectedDto.id}`);
    };

    // Ask for delete confirmation
    const confirmDelete = (accept, reject) =>
    {
        confirmDialog({
            message: 'Weet je zeker dat je dit dossier wilt verwijderen?',
            header: 'Bevestiging',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Ja',
            rejectLabel: 'Nee',
            accept,
            reject
        });
    };

    // Delete an item
    const deleteDto = () =>
    {
        if (selectedDto === null)
            showNothingSelected();
        else
        {
            // Ask for confirmation
            confirmDelete(
                () =>
                {
                    // Do the delete call
                    UserFileService.Delete(selectedDto.id).then(() =>
                    {
                        // Show message 
                        toast.current.show({ severity: 'success', summary: 'Verwijderd', detail: 'Het dossier is succesvol verwijderd' });
                        setRefreshTrigger(!refreshTrigger);
                    }).
                    catch((err) =>
                    {
                        ErrorHandler.ToastErrors(toast, 'Er zijn een of meerdere fouten opgetreden bij het verwijderen: ', err);
                    });
                },
                () =>
                {
                }
            );
        }
    };

    const equalOrEmptyFilter = (value, filter) =>
    {
        return filter === null ? true : value === filter;
    };

    const partnerFilterTemplate = (options) =>
    {
        return <Dropdown value={options.value} optionValue="id" optionLabel="name" options={partnerList}
            onChange={(e) => options.filterApplyCallback(e.value)}
            className="p-column-filter" emptyMessage="Geen resultaten gevonden" />;
    };

    const partnerBodyTemplate = (rowData) =>
    {
        return partnerList.find((item) => item.id == rowData.partnerID)?.companyName;
    }

    const stateFilterTemplate = (options) =>
    {
        const statusList = [
            { id: 1, name: "Nieuw" },
            { id: 2, name: "Bevestigd" },
            { id: 3, name: "Verlopen" },
        ];
        return <Dropdown value={options.value} optionValue="id" optionLabel="name" options={statusList}
            onChange={(e) => options.filterApplyCallback(e.value)}
            className="p-column-filter" emptyMessage="Geen resultaten gevonden" />;
    }

    const stateBodyTemplate = (rowData) =>
    {
        switch (rowData.state)
        {
            case 1:
                return "Nieuw";
            case 2:
                return "Bevestigd";
            case 3:
                return "Verlopen";
            default:
                return "Onbekend";
        }
    }

    const activationDateBody = (rowData) =>
    {
        return rowData.activationDate === null ? "" : moment(rowData.activationDate).format("DD-MM-YYYY");
    }

    if (dtoList === null || partnerList === null)
        return (<div>Laden...</div>);

    return (
        <div>
            <ConfirmDialog />
            <Toast ref={toast} />
            <div className="overview">
                <DataTable
                    value={dtoList}
                    paginator={true}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="{first} - {last} van {totalRecords}"
                    rowsPerPageOptions={[10, 20, 50]}
                    size="small"
                    dataKey="id"
                    filterDisplay="row"
                    loading={dtoList === null}
                    selectionMode="single"
                    rows={10}
                    responsiveLayout="scroll"
                    selection={selectedDto}
                    onSelectionChange={(e) => { setSelectedDto(e.value); }}
                    emptyMessage="Geen resultaten gevonden.">
                    <Column field="id" header="#" filter={false} sortable />
                    <Column field="surname"
                        header="Achternaam overledene"
                        sortable
                        filter
                        filterMatchMode="contains"
                        showFilterMenu={false}
                        filterPlaceholder="Zoek op achternaam" />
                    <Column field="firstname"
                        header="Voornaam overledene"
                        sortable
                        filter
                        filterMatchMode="contains"
                        showFilterMenu={false}
                        filterPlaceholder="Zoek op voornaam" />
                    <Column field="partnerID"
                        header="Partner"
                        sortable
                        filter
                        filterElement={partnerFilterTemplate}
                        filterMatchMode="custom" filterFunction={equalOrEmptyFilter}
                        body={partnerBodyTemplate} />
                    <Column field="state"
                        header="Status"
                        sortable
                        filter
                        filterElement={stateFilterTemplate}
                        filterMatchMode="custom" filterFunction={equalOrEmptyFilter}
                        body={stateBodyTemplate} />
                    <Column field="activationDate"
                        header="Activatie datum"
                        filter={false}
                        sortable
                        body={activationDateBody} />
                        
                </DataTable>
            </div>
            <div className="buttons">
                <input type="submit" value="Inzien" onClick={openDto} />
                <input type="submit" value="Verwijderen" onClick={deleteDto} />
            </div>
        </div>
    );
}

export default Overview;