import React from "react";
import Header from '../Components/Header/Header';
import Login from '../Components/Login/Login';

function LoginPage()
{
    return (
        <div id="upd-main">
            <Header />
            <main>
                <Login />
            </main>
        </div>
    );
}


export default LoginPage;