import React from "react";
import Header from '../Components/Header/Header';
import RegisterContactPerson from '../Components/Register/RegisterContactPerson/RegisterContactPerson';
import Footer from '../Components/Footer/Footer';


function RegisterPage()
{
    return (
        <div id="upd-main">
            <Header />
            <RegisterContactPerson />
            <Footer />
        </div>
    );
}


export default RegisterPage;