import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import ErrorHandler from '../../ErrorHandler';
import PartnerService from '../../../Service/PartnerService';
import UploadHandler from '../../UploadHandler';

function Item()
{
    const toast = useRef(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const [title, setTitle] = useState(null);
    const [dto, setDto] = useState(null);
    
    useEffect(() =>
    {
        if (id === undefined)
        {
            navigate('..');
        }
        else
        {
            setTitle("Bewerken");
            PartnerService.Get(id).then((res) =>
            {
                setDto(res.data);
            });
        }
    }, []);

    const handleLogoFileChange = (e) =>
    {
        if (e.target.files)
        {
            UploadHandler.GetAttachmentDto(e.target.files[0]).then((res) =>
            {
                setDto({ ...dto, logo: res });
            });
        }
    };

    // Save the current dto
    const saveDto = async (e) =>
    {
        // Prevent default submit behaviour
        e.preventDefault();

        PartnerService.Update(dto).then((res) =>
        {
            navigate('..', { state: { toastParams: { severity: 'success', summary: 'Opgeslagen', detail: 'De partner is opgeslagen' } }});
        })
        .catch((err) =>
        {
            ErrorHandler.ToastErrors(toast, 'Er zijn een of meerdere fouten opgetreden bij het opslaan: ', err);
        });
    };


    if (title === null || dto === null)
        return (<div className="editContainer">Laden...</div>);

    return (
        <div className="editContainer">
            <Toast ref={toast} />
            <h1>{title}</h1>
            <form onSubmit={saveDto} autoComplete="off">
                <div className="fields">
                    <div className="field">
                        <label htmlFor="txtCompanyName">Bedrijfsnaam:</label>
                        <input
                            id="txtCompanyName"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            defaultValue={dto.companyName}
                            onChange={(e) => setDto({ ...dto, companyName: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="uplLogo">Logo:</label>
                        <input type="file" accept=".jpg,.jpeg" onChange={handleLogoFileChange} /> <br />
                        {dto.logo.file !== undefined && dto.logo.file !== null ?
                            <><br /><img className="imagepreview" src={`data:${dto.logo.mimetype};base64,${dto.logo.file}`} title={dto.logo.filename} /></>
                            : <></>
                        }
                    </div>
                </div>
                <div className="buttons">
                    <input type="submit" value="Opslaan" />
                    <Link to='..'>
                        <input type="submit" value="Annuleren" />
                    </Link>
                </div>
            </form>
        </div>
    );
}

export default Item;