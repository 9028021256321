import baseService from "./BaseService";

const Get = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/Status/list' + (id !== undefined ? '/' + id : ''), {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) =>
            {
                reject(error);
            });
    });
};

const Update = (statusDto) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put('/Status/update', statusDto)
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) =>
            {
                reject(error);
            });
    });
};

const Evidence = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/Status/evidence/${id}`, {
                headers: {
                    Accept: 'application/octet-stream',
                },
                responseType: 'blob'
            })
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) =>
            {
                reject(error);
            });
    });
};

const StatusService = {
    Get,
    Update,
    Evidence
};

export default StatusService;