import React, { useState, useEffect, useRef } from "react";
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import moment from 'moment'
import AuthService from "../../../Service/AuthService";
import UserService from "../../../Service/UserService";
import UserFileService from "../../../Service/UserFileService";
import './DashboardMain.scss';
import UploadHandler from "../../UploadHandler";
import PowerOfAttorneyService from "../../../Service/PowerOfAttorneyService";
import ErrorHandler from "../../ErrorHandler";

function DashboardMain()
{
	const toastSign = useRef(null);
	const [statusLoaded, setStatusLoaded] = useState(false);
	const [currentFileName, setCurrentFileName] = useState([]);
	const [accountValidUntil, setAccountValidUntil] = useState("");
	const [selected, setSelected] = useState(false);
	const [deathCertificateUploaded, setDeathCertificateUploaded] = useState(false);
	const [powerOfAttorneySigned, setPowerOfAttorneySigned] = useState(false);
	const [showUploadDialog, setShowUploadDialog] = useState(false);
	const [deathCertificateDto, setDeathCertificateDto] = useState(null);
	const [showSignDialog, setShowSignDialog] = useState(false);
	const [userDto, setUserDto] = useState(false);
	const [userFileDto, setUserFileDto] = useState(false);
	const [poaDto, setPoaDto] = useState(null);
	const [signDto, setSignDto] = useState(null);

	useEffect(() =>
	{
		// Refresh the status if not yet loaded
		if (!statusLoaded)
		{ 
			UserFileService.RefreshStatus().then(() =>
			{
				setStatusLoaded(true);
			});
		}
	}, []);

	useEffect(() =>
	{
		const status = UserFileService.GetStatus();
		if (!statusLoaded || status === null || status === undefined)
		{
			UserFileService.RefreshStatus().then(() =>
			{
				setStatusLoaded(true);
			});
		}
		
		if (status !== null && status !== undefined)
		{
			setCurrentFileName(status.fullname);
			moment.locale('nl');
			setAccountValidUntil(moment(status.valid_until).format('D MMMM YYYY'));
			setSelected(status.items_selected);
			setDeathCertificateUploaded(status.dead_certificate_uploaded);
			setPowerOfAttorneySigned(status.power_of_attorney_signed);
		}
		else
			setStatusLoaded(false);
	}, [statusLoaded]);

	const ImageSource = (checked) =>
	{
		if (checked)
			return '/images/icon-checkdone.png';
		else
			return '/images/icon-nocheck.png';
	}

	const HandleDeathCertificateUploadChange = (e) =>
	{
		if (e.target.files)
		{
			UploadHandler.GetAttachmentDto(e.target.files[0]).then((res) =>
			{
				// Store in state
				setDeathCertificateDto(res);
			});
		}
	};

	const UploadDeathCertificate = () =>
	{
		UserFileService.UploadDeathCertificate(deathCertificateDto).then(() =>
		{
			setShowUploadDialog(false);

			// Refresh states
			setStatusLoaded(false);
		});
	};

	const DownloadDeathCertificate = () =>
	{
		// Download death certificate
		UserFileService.CurrentDeathDertificate().then((res) =>
		{
			// Create a new blobl object
			const objUrl = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
			const link = document.createElement('a');
			link.href = objUrl;
			link.target = "_blank";
			link.setAttribute('download', `Akte van overlijden.pdf`);
			document.body.appendChild(link);
			link.click();
		});
	};

	const InitSignDialog = () =>
	{

		// Get current user data
		UserService.Get(AuthService.getAuth().user_id).then((res) =>
		{
			// Store data
			setUserDto(res.data);

			// Get current UserFile data
			UserFileService.Get(AuthService.getAuth().userfile_id).then((res) =>
			{
				// Store data
				setUserFileDto(res.data);

				PowerOfAttorneyService.GetFor(AuthService.getAuth().userfile_id).then((res) =>
				{
					// Store data
					setPoaDto(res.data);

					// Show dialog to sign
					setShowSignDialog(true);
				})
				.catch((err) =>
				{
					// No data yet, leave dto null

					// Create new sign dto
					setSignDto({
						checked: false,
						name: '',
						city: '',
						date: new Date()
					});

					// Show dialog to sign
					setShowSignDialog(true);
				});
			});

		});
	};

	const Sign = (e) =>
	{
		e.preventDefault();

		// Validate
		if (!signDto.checked)
		{
			ErrorHandler.ToastErrorMessage(toastSign, 'U dient akkoord te gaan met de volmacht voordat er ondertekend kan worden');
			return;
		}

		if (signDto.name.trim().length == 0)
		{
			ErrorHandler.ToastErrorMessage(toastSign, 'Het is verplicht om een naam in te voeren');
			return;
		}

		if (signDto.city.trim().length == 0)
		{
			ErrorHandler.ToastErrorMessage(toastSign, 'Het is verplicht om een plaats in te voeren');
			return;
		}

		// Sign
		PowerOfAttorneyService.Create(signDto).then((res) =>
		{
			toastSign.current.show({ severity: 'success', summary: 'Opgeslagen', detail: 'De volmacht is ondertekend' });

			console.log("sign done");

			// Close dialog
			setShowSignDialog(false);

			// Refresh states
			setStatusLoaded(false);
			console.log("setStatusLoaded false");
		})
		.catch((err) => {
			ErrorHandler.ToastErrors(toastSign, 'Er zijn een of meerdere fouten opgetreden bij ondertekenen: ', err);
		});
	};

	if (!statusLoaded)
		return "";

    return (
		<section>
			<div className="dashboard-acc-geldig">account geldig tot {accountValidUntil}</div>
			<h1>Gegevens overledene</h1>
			<h2>{currentFileName}</h2>
			<div className="dashboard-main">
				<div><img src={ImageSource(selected) } /></div>
				<div><img src={ImageSource(deathCertificateUploaded)} /></div>
				<div><img src={ImageSource(powerOfAttorneySigned)} /></div>
				<div></div>
				<div>{deathCertificateUploaded ? (<img src="/images/icon-pdf.svg" onClick={DownloadDeathCertificate} className="downloadIcon" />): ("")}</div>					
				<div>{powerOfAttorneySigned ? (<img src="/images/icon-pdf.svg" />) : ("")}</div>
				<div>Stap 1: selecteren <br />en invullen</div>
				<div><a onClick={(e) => { setShowUploadDialog(true); }}>Stap 2: upload  <br /> overlijdensakte</a></div>
				<div><a onClick={InitSignDialog}>Stap 3: ondertekening  <br /> volmacht</a></div>
			</div>
			<Dialog header="Upload akte van overlijden" visible={showUploadDialog} onHide={() => setShowUploadDialog(false)}
				style={{ width: '75vw' }}>
				<input type="file" accept=".pdf" onChange={HandleDeathCertificateUploadChange} />
				<div className="buttons">
					<input type="submit" name="" value="Opslaan" onClick={UploadDeathCertificate} />
				</div>
			</Dialog>
			<Dialog header="Ondertekening volmacht" visible={showSignDialog} onHide={() => setShowSignDialog(false)}
				style={{ width: '75vw' }}>
				<b>Volmachtgever</b><br />
				{userDto.firstname} {userDto.surname}<br />
				{userDto.street} {userDto.housenumber}{userDto.housenumberaddition}<br />
				{userDto.zipcode} {userDto.city} <br />
				{userDto.phonenumber}<br />
				{userDto.email}<br />
				<br />
				<b>Volmachtnemer:</b><br />
				Nazorgdesk<br />
				Postbus 438<br />
				4460AV Goes<br />
				0113-854944<br />
				info@nazorgdesk.nl<br />
				Vertegenwoordigd door: Dhr. A. de Man MBA in hoedanigheid van directeur.<br />
				<br />
				komen het volgende overeen:<br />
				<br />
				Volmachtgever geeft volmachtnemer opdracht, welke door volmachtnemer wordt aanvaard, t.a.v. het
				informeren van instanties waar overledene abonnementen, overeenkomsten, of andersoortige
				verbintenissen had lopen. Met het informeren kan worden bedoeld het louter informeren, maar ook
				het opzeggen van deze verbintenissen, alsmede een verzoek tot wijziging van een overeenkomst.<br />
				<br />
				Volmachtgever verklaart bevoegd te zijn deze volmacht af te geven op grond van de aan volmachtgever
				toekomende wettelijke of testamentaire rechten en plichten.<br />
				<br />
				<b>Overledene:</b><br />
				{userFileDto.firstname} {userFileDto.surname}<br />
				Laatste woonadres:
				{userFileDto.street} {userFileDto.housenumber}{userFileDto.housenumberaddition}<br />
				{userFileDto.zipcode} {userFileDto.city} <br />
				<br />
				{
					poaDto === null ? (
						<>
							<Toast ref={toastSign} />
							<form onSubmit={Sign}>
								<div className="fields">
									<div className="field">
										<label htmlFor="cbRead" className="pointer">Ik verklaar deze volmacht gelezen te hebben en ga hiermee akkoord:</label>
										<input
											type="checkbox"
											name="cbRead"
											id="cbRead"
											required
											defaultChecked={signDto?.checked }
											onChange={(e) => setSignDto({ ...signDto, checked: e.target.checked })} />
										<span className="checkmark"></span>
									</div>
									<div className="field">
										<label htmlFor="txtSignedBy">Ondertekend door: </label>
										<input
											type="text"
											name="txtSignedBy"
											id="txtSignedBy"
											required
											defaultValue={signDto?.name}
											onChange={(e) => setSignDto({...signDto, name: e.target.value})} />
									</div>
									<div className="field">
										<label htmlFor="txtPlace">te:</label>
										<input
											type="text"
											name="txtPlace"
											id="txtPlace"
											required
											defaultValue={signDto?.city}
											onChange={(e) => setSignDto({...signDto, city: e.target.value}) }/>
									</div>
									<div className="field">
										<label htmlFor="txtDate">Datum:</label>
										<input type="text" name="txtDate" id="txtDate" readOnly value={moment(signDto?.date).format('DD-MM-yyyy')} />
									</div>
								</div>
								<div className="buttons">
									<input type="submit" value="Ondertekenen" />
								</div>
							</form>
						</>
					) :
						<>Volmacht ondertekend door <i>{poaDto.name}</i> te <i>{poaDto.city}</i> op {moment(poaDto.dateTimeCreated).format('DD-MM-yyyy')}</>
				}

			</Dialog>
		</section>
    );
}

export default DashboardMain;
