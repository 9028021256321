import React from "react";
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';


function PageNotFound()
{
    return (
        <div id="upd-main">
            <Header />
            <main>
            </main>
            <Footer />
        </div>
    );
}

export default PageNotFound;