import baseService from "./BaseService";

const Get = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/Form/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const List = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/Form/list', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const Create = (dto) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put('/Form/create', dto)
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const Delete = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .delete(`/Form/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const Update = (dto) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put('/Form/update', dto)
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const Finalize = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(`/Form/finalize/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const LinkAgencies = (agencies) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put('/Form/linkagencies', agencies)
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
    
};

const FormService = {
    Get,
    List,
    Create,
    Delete,
    Update,
    Finalize,
    LinkAgencies
};

export default FormService;