import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import Select from 'react-select';
import ErrorHandler from '../../ErrorHandler';
import CategoryService from '../../../Service/CategoryService'
import AgencyService from '../../../Service/AgencyService'

function Item()
{
    const toast = useRef(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const [title, setTitle] = useState(null);
    const [dto, setDto] = useState(null);
    const [categoryList, setCategoryList] = useState([]);

    useEffect(() =>
    {
        CategoryService.List().then((res) =>
        {
            setCategoryList(res.data);
        });

        if (id === undefined)
        {
            // New item
            setTitle("Toevoegen");
            setDto({ landingPage: false, active: true });
        }
        else
        {
            setTitle("Bewerken");
            AgencyService.Get(id).then((res) =>
            {
                setDto(res.data);
            });
        }
    }, []);

    // Save the current dto
    const saveDto = async (e) =>
    {
        // Prevent default submit behaviour
        e.preventDefault();

        let saveCallback = null;
        if (dto.id === null || dto.id === undefined)
            saveCallback = AgencyService.Create(dto);
        else
            saveCallback = AgencyService.Update(dto);

        saveCallback.then((res) =>
        {
            navigate('..', { state: { toastParams: { severity: 'success', summary: 'Opgeslagen', detail: 'De instantie is opgeslagen' } }});
        })
        .catch((err) =>
        {
            ErrorHandler.ToastErrors(toast, 'Er zijn een of meerdere fouten opgetreden bij het opslaan: ', err);
        });
    };


    if (title === null || dto === null)
        return (<div className="editContainer">Laden...</div>);

    return (
        <div className="editContainer">
            <Toast ref={toast} />
            <h1>{title}</h1>
            <form onSubmit={saveDto} autoComplete="off">
                <div className="fields">
                    <div className="field">
                        <label htmlFor="ddlCategory">Categorie:</label>
                        <Select
                            id="ddlCategory"
                            required={true}
                            options={categoryList}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            placeholder="Selecteer een categorie"
                            value={categoryList.find((element) => { return element.id == dto.categoryID })}
                            onChange={(item) => setDto({ ...dto, categoryID: item.id })}
                            isSearchable={true}
                            isMulti={false} />
                    </div>
                    <div className="field">
                        <label htmlFor="txtName">Naam:</label>
                        <input
                            id="txtName"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            defaultValue={dto.name}
                            onChange={(e) => setDto({ ...dto, name: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="cbHomepage">Weergeven op homepage:</label>
                        <input
                            id="cbHomepage"
                            type="checkbox"
                            checked={dto.landingPage}
                            onChange={e => setDto({ ...dto, landingPage: e.target.checked })}
                        />
                            <span className="checkmark"></span>
                    </div>
                    <div className="field">
                        <label htmlFor="cbActive">Actief:</label>
                        <input
                            id="cbActive"
                            type="checkbox"
                            checked={dto.active}
                            onChange={e => setDto({ ...dto, active: e.target.checked })}
                        />
                        <span className="checkmark"></span>
                    </div>
                </div>
                <div className="buttons">
                    <input type="submit" value="Opslaan" />
                    <Link to='..'>
                        <input type="submit" value="Annuleren" />
                    </Link>
                </div>
            </form>
        </div>
    );
}

export default Item;