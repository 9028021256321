import React from "react";
import './Slider.scss';

function Slider()
{
    return (
        <div className="startpagina-header">

            <h2>Informeer in één keer</h2>
            <h1><strong>alle organisaties</strong></h1>

            <div className="slider">
                <div className="slide-track-1">
                    <div className="slide">
                        <img src="/images/ad.png" alt="ad" />
                    </div>
                    <div className="slide">
                        <img src="/images/cz.png" alt="cz" />
                    </div>
                    <div className="slide">
                        <img src="/images/delta.png" alt="delta" />
                    </div>
                    <div className="slide">
                        <img src="/images/eneco.png" alt="eneco" />
                    </div>
                    <div className="slide">
                        <img src="/images/evides.png" alt="evides" />
                    </div>
                    <div className="slide">
                        <img src="/images/facebook-logo.png" alt="facebook" />
                    </div>
                    <div className="slide">
                        <img src="/images/instagram.png" alt="instagram" />
                    </div>
                    <div className="slide">
                        <img src="/images/kpn.png" alt="kpn" />
                    </div>
                    <div className="slide">
                        <img src="/images/ov-chipkaart.png" alt="ov-chipkaart" />
                    </div>
                    <div className="slide">
                        <img src="/images/tele2.png" alt="tele2" />
                    </div>
                    <div className="slide">
                        <img src="/images/telegraaf.png" alt="telegraaf" />
                    </div>
                    <div className="slide">
                        <img src="/images/t-mobile.png" alt="t-mobile" />
                    </div>
                    <div className="slide">
                        <img src="/images/unicef.png" alt="unicef" />
                    </div>
                    <div className="slide">
                        <img src="/images/ziggo.png" alt="ziggo" />
                    </div>
                    <div className="slide">
                        <img src="/images/zlm.png" alt="zlm" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Slider;