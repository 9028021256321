import baseService from "./BaseService";
import AuthService from "./AuthService";

// Get the list of public agencies.
const getAgencyList = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/Agency/public', { })
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Get the list of active agencies.
const getActiveAgencyList = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/Agency/active', { })
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const RequestAgency = (dto) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .post('/Agency/request', dto)
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const Get = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/Agency/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const List = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/Agency/list', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const Create = (dto) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put('/Agency/create', dto)
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const Delete = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .delete(`/Agency/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const Update = (dto) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put('/Agency/update', dto)
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const AgencyService = {
    getAgencyList,
    getActiveAgencyList,
    RequestAgency,
    Get,
    List,
    Create,
    Delete,
    Update
};

export default AgencyService;