import { NavLink, useNavigate, useParams, Outlet } from 'react-router-dom';
import './Overview.scss';

function Overview()
{
    return (
        <div>
            <div className="management-menu">
                <NavLink to='/beheer/categorie'>
                    <input type="button" value="Categorie&euml;n" className="btn-management" />
                </NavLink>
                <NavLink to='/beheer/instantie'>
                    <input type="button" value="Instanties" className="btn-management" />
                </NavLink>
                <NavLink to='/beheer/formulier'>
                    <input type="button" value="Sjablonen" className="btn-management" />
                </NavLink>
                <NavLink to='/beheer/dossier'>
                    <input type="button" value="Dossiers" className="btn-management" />
                </NavLink>
                <NavLink to='/beheer/partner'>
                    <input type="button" value="Partners" className="btn-management" />
                </NavLink>
                <NavLink to='/beheer/gebruiker'>
                    <input type="button" value="Gebruikers" className="btn-management" />
                </NavLink>
            </div>
            <Outlet />
        </div>
    );
}


export default Overview;