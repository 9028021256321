import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import moment from 'moment';
import UserFileService from '../../../Service/UserFileService';
import PartnerService from '../../../Service/PartnerService';

function PartnerOverview()
{
    const toast = useRef(null);
    const navigate = useNavigate();
    const [dtoList, setDtoList] = useState(null);
    const [selectedDto, setSelectedDto] = useState(null);

    moment.locale('nl');

    // Check for toast message
    const { state } = useLocation();
    useEffect(() =>
    {
        if (state !== null)
        {
            let { toastParams } = state;
            if (toast.current !== null && toastParams !== null && toastParams !== undefined)
            {
                toast.current.show(toastParams);
                // Clear state
                delete state.toastParams;
            }
        }

    }, [toast.current, dtoList]);

    useEffect(() =>
    {
        // Retrieve all the items
        UserFileService.List().then((res) =>
        {
            setDtoList(res.data);
        });
    }, []);

    // Show error toast, nothing selected
    const showNothingSelected = () => 
    {
        toast.current.show({ severity: 'error', summary: 'Fout', detail: 'Er is geen dossier geselecteerd' });
    }

    // Load edit 
    const EditDto = () =>
    {
        if (selectedDto === null)
            showNothingSelected();
        else
            navigate(`./${selectedDto.id}`);
    };

    const equalOrEmptyFilter = (value, filter) =>
    {
        return filter === null ? true : value === filter;
    };

    const stateFilterTemplate = (options) =>
    {
        const statusList = [
            { id: 1, name: "Nieuw" },
            { id: 2, name: "Bevestigd" },
            { id: 3, name: "Verlopen" },
        ];
        return <Dropdown value={options.value} optionValue="id" optionLabel="name" options={statusList}
            onChange={(e) => options.filterApplyCallback(e.value)}
            className="p-column-filter" emptyMessage="Geen resultaten gevonden" />;
    }

    const stateBodyTemplate = (rowData) =>
    {
        switch (rowData.state)
        {
            case 1:
                return "Nieuw";
            case 2:
                return "Bevestigd";
            case 3:
                return "Verlopen";
            default:
                return "Onbekend";
        }
    }

    const activationDateBody = (rowData) =>
    {
        return moment(rowData).format("DD-MM-YYYY");
    }

    if (dtoList === null)
        return (<div>Laden...</div>);

    return(
        <div>
            <Toast ref={toast} />
            <div className="overview">
                <DataTable
                    value={dtoList}
                    paginator={true}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="{first} - {last} van {totalRecords}"
                    rowsPerPageOptions={[10, 20, 50]}
                    size="small"
                    dataKey="id"
                    filterDisplay="row"
                    loading={dtoList === null}
                    selectionMode="single"
                    rows={10}
                    responsiveLayout="scroll"
                    selection={selectedDto}
                    onSelectionChange={(e) => { setSelectedDto(e.value); }}
                    emptyMessage="Geen resultaten gevonden.">
                    <Column field="id" header="#" filter={false} sortable />
                    <Column field="firstname"
                        header="Voornaam"
                        sortable
                        filter
                        filterMatchMode="contains"
                        showFilterMenu={false}
                        filterPlaceholder="Zoek op voornaam" />
                    <Column field="surname"
                        header="Achternaam"
                        sortable
                        filter
                        filterMatchMode="contains"
                        showFilterMenu={false}
                        filterPlaceholder="Zoek op achternaam" />
                    <Column field="state"
                        header="Status"
                        sortable
                        filter
                        filterElement={stateFilterTemplate}
                        filterMatchMode="custom" filterFunction={equalOrEmptyFilter}
                        body={stateBodyTemplate} />
                    <Column field="activationDate"
                        header="Activatie datum"
                        filter={false}
                        sortable
                        body={activationDateBody} />
                </DataTable>
            </div>
            <div className="buttons">
                <Link to='./toevoegen'>
                    <input type="submit" value="Toevoegen" />
                </Link>
                <input type="submit" value="Bewerken" onClick={EditDto} />
            </div>
        </div>
    );

};

export default PartnerOverview;