import React, { Component } from "react";
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';

function PaymentPage()
{
    return (
        <div id="udp-main">
            <Header />
            <main>
                <h1>Betaling mislukt</h1>
                De betaling is mislukt
            </main>
            <Footer />
        </div>
    );
}

export default PaymentPage;