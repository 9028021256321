import baseService from "../Service/BaseService";

// Get the list of public agencies.
const Create = (dto) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put('/PowerOfAttorney/create', dto)
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const GetFor = (userfile_id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/PowerOfAttorney/getfor/${userfile_id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const Evidence = (userfile_id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/PowerOfAttorney/evidence/${userfile_id}`, {
                headers: {
                    Accept: 'application/octet-stream'
                },
                responseType: 'blob'
            })
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};


const PowerOfAttorneyService = {
    Create,
    GetFor,
    Evidence
};

export default PowerOfAttorneyService;