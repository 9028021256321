import './Login.scss';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import AuthService from '../../Service/AuthService';
import baseService from '../../Service/BaseService';
import PermissionService from '../../Service/PermissionService'

function Login()
{

    // Determine start page for the user
    const afterAuthPage = () =>
    {
        if (PermissionService.hasPermission('User'))
            return '/dashboard';
        else if (PermissionService.hasPermission('Admin'))
            return '/beheer';
        else if (PermissionService.hasPermission('Partner'))
            return '/partner';
    };

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [confirmMessage, setConfirmMessage] = useState("");
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => { setPasswordShown(!passwordShown); }

    const navigate = useNavigate();
    const { state } = useLocation();

    useEffect(() =>
    {
        // Check if we are already authenticated
        if (PermissionService.isAuthenticated())
            navigate(afterAuthPage(), { replace: true });

        if (state !== null)
        {
            const { confirmedMessage } = state;
            setConfirmMessage(confirmedMessage);
            const { password } = state;
            setPassword(password);
            const { message } = state;
            setErrorMessage(message);
        }
    }, []);

    useEffect(() =>
    {
        setErrorMessage("");
    }, [email, password]);

    const handleSubmit = async (e) =>
    {
        e.preventDefault();
        try
        {
            const response = await baseService.post("/User/authorize", {
                email,
                password,
            });

            if (response?.status === 200)
            {
                if (response.data.accesstoken)
                {
                    AuthService.setAuth(response.data);
                    if (AuthService.getLocalAccessToken)
                    {
                        if (response.data.redirect_url !== undefined && response.data.redirect_url !== null)
                        {
                            AuthService.getPermissions().then((res) =>
                            {
                                AuthService.setPermissions(res);
                            });
                            window.location.href = response?.data.redirect_url;
                            return null;
                        }

                        AuthService.getPermissions().then(() =>
                        {
                            // Redirect to the page for our user
                            navigate(afterAuthPage(), { replace: true })
                        });
                    }
                }
            }
        }
        catch (err)
        {
            if (!err?.response)
            {
                setErrorMessage("No Server Response");
            } else if (err.response?.status === 400)
            {
                setErrorMessage("De ingevoerde inloggegevens zijn onjuist");
            } else if (err.response?.status === 401)
            {
                setErrorMessage("Unauthorized");
            } else
            {
                setErrorMessage("Login Failed");
            }
        }
    };

    return (
        <div className="wrapperlogin">
            <div className="logintext">Inloggen</div>
            <form onSubmit={handleSubmit}>
                <div>
                    <div className="error-message">
                        <p
                            className="LoginError"
                            aria-live="assertive"
                        >
                            {errorMessage}
                        </p>
                        <div className="clear"></div>
                    </div>
                    <div className="confirm-message">
                        <p
                            className="user-management-confirm "
                            aria-live="assertive"
                        >
                            {confirmMessage}
                        </p>
                        <div className="clear"></div>
                    </div>
                    <div className="login-field-holder">
                        <input
                            type="email"
                            id="email"
                            className="login-field"
                            placeholder="Vul je e-mailadres in"
                            required
                            onChange={(e) => setEmail(e.target.value)}
                        >
                        </input>
                    </div>
                    <div className="login-field-holder">
                        <input
                            type={passwordShown ? "text" : "password"}
                            id="password"
                            className="login-field"
                            placeholder="Wachtwoord"
                            required
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        >
                        </input>
                        <span className="password-toggle" onClick={togglePassword}>Toon</span>
                    </div>
                </div>

                <center>
                    <input type="submit" value="Inloggen" className="btn-inloggen" />
                </center>
                <center className="forgot-password">
                    <Link to="/ForgotPassword">
                        <span className="wachtwoordtext">
                            Ik ben mijn wachtwoord vergeten
                            <span className="wachtwoord-icon">
                                <i className="fa-solid fa-chevron-right" />
                            </span>
                        </span>
                    </Link>
                </center>
                <center>
                    <Link to="/registreren" className="link-maakacc">
                        <span className="btn-maakacc">
                            Maak een account aan
                        </span>
                    </Link>
                </center>
            </form>
        </div>
    );
}

export default Login;