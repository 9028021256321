import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import Select from 'react-select';
import ErrorHandler from '../../ErrorHandler';
import FormFieldService from '../../../Service/FormFieldService';
import FormService from '../../../Service/FormService';

function Item()
{
    const toast = useRef(null);
    const navigate = useNavigate();
    const { id, fieldId } = useParams();
    const [title, setTitle] = useState(null);
    const [dto, setDto] = useState(null);
    const [formDto, setFormDto] = useState([]);

    const fieldList = [
        { id: 1, name: "Tekstveld" },
        { id: 2, name: "Checkbox" },
    ];

    useEffect(() =>
    {
        // Get the form
        FormService.Get(id).then((res) =>
        {
            setFormDto(res.data);
        });
    }, []);

    useEffect(() =>
    {
        if (fieldId === undefined)
        {
            // New item
            setTitle("Toevoegen");
            setDto({
                formVersionID: formDto.currentVersionID,
                required: false,
                hideFollowing: false
            });
        }
        else
        {
            setTitle("Bewerken");
            FormFieldService.Get(fieldId).then((res) =>
            {
                setDto(res.data);
            });
        }
    }, [formDto])

    // Save the current dto
    const saveDto = async (e) =>
    {
        // Prevent default submit behaviour
        e.preventDefault();

        let saveCallback = null;
        if (dto.id === null || dto.id === undefined)
            saveCallback = FormFieldService.Create(dto);
        else
            saveCallback = FormFieldService.Update(dto);

        saveCallback.then((res) =>
        {
            navigate('..', { state: { toastParams: { severity: 'success', summary: 'Opgeslagen', detail: 'Het veld is opgeslagen' } }});
        })
        .catch((err) =>
        {
            ErrorHandler.ToastErrors(toast, 'Er zijn een of meerdere fouten opgetreden bij het opslaan: ', err);
        });
    };

    if (title === null || dto === null)
        return (<div className="editContainer">Laden...</div>);

    return (
        <div className="editContainer">
            <Toast ref={toast} />
            <h1>{title}</h1>
            <form onSubmit={saveDto} autoComplete="off">
                <div className="fields">
                    <div className="field">
                        <label htmlFor="txtName">Naam:</label>
                        <input
                            id="txtName"
                                type="text"
                                required={true}
                                onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                                onInput={e => e.target.setCustomValidity("")}
                                title="Dit veld is verplicht"
                                defaultValue={dto.name}
                                onChange={(e) => setDto({ ...dto, name: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="ddlFieldType">Veld type:</label>
                        <Select
                            id="ddlFieldType"
                            required={true}
                            options={fieldList}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            placeholder="Selecteer een type veld"
                            value={fieldList.find((element) => { return element.id == dto.fieldType })}
                            onChange={(item) => setDto({ ...dto, fieldType: item.id })}
                            isSearchable={true}
                            isMulti={false} />
                    </div>
                    <div className="field">
                        <label htmlFor="cbRequired">Verplicht:</label>
                        <input
                            id="cbRequired"
                            type="checkbox"
                            checked={dto.required}
                            onChange={e => setDto({ ...dto, required: e.target.checked })}
                        />
                        <span className="checkmark"></span>
                    </div>
                    <div className="field">
                        <label htmlFor="cbHideFollowing">Opvolgende velden verbergen:</label>
                        <input
                            id="cbHideFollowing"
                            type="checkbox"
                            checked={dto.hideFollowing}
                            onChange={e => setDto({ ...dto, hideFollowing: e.target.checked })}
                        />
                        <span className="checkmark"></span>
                    </div>
                </div>
                <div className="buttons">
                    <input type="submit" value="Opslaan" />
                    <Link to='..'>
                        <input type="submit" value="Annuleren" />
                    </Link>
                </div>
            </form>
        </div>   
    );
}

export default Item;