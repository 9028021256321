import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import ErrorHandler from '../../ErrorHandler';
import UserFileService from '../../../Service/UserFileService'
import StatusService from '../../../Service/StatusService';
import UploadHandler from '../../UploadHandler';

function UserFileItemForPartner()
{
    const toast = useRef(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const [title, setTitle] = useState(null);
    const [dto, setDto] = useState(null);

    useEffect(() =>
    {
        if (id === undefined)
        {
            // Go back, only viewing a specif id is supported
            navigate('..');
        }
        else
        {
            setTitle("Bewerken gegevens overledene");

            // Get userfile
            UserFileService.Get(id).then((res) =>
            {
                setDto(res.data);
            });
        }
    }, []);

    // Save the current dto
    const SaveDto = async (e) =>
    {
        // Prevent default submit behaviour
        e.preventDefault();

        UserFileService.Update(dto).then((res) =>
        {
            navigate('..', { state: { toastParams: { severity: 'success', summary: 'Opgeslagen', detail: 'Het dossier is opgeslagen' } } });
        })
        .catch((err) =>
        {
            ErrorHandler.ToastErrors(toast, 'Er zijn een of meerdere fouten opgetreden bij het opslaan: ', err);
        });
    };

    const HandleFileChange = (e) =>
    {
        if (e.target.files)
        {
            UploadHandler.GetAttachmentDto(e.target.files[0]).then((res) =>
            {
                setDto({ ...dto, deathcertificate: res });
            });
        }
    };

    const DownloadDeathCertificate = () =>
    {
        // Download death certificate
        UserFileService.DeathDertificate(dto.id).then((res) =>
        {
            // Create a new blobl object
            const objUrl = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = objUrl;
            link.target = "_blank";
            link.setAttribute('download', `Akte van overlijden - ${dto.id}.pdf`);
            document.body.appendChild(link);
            link.click();
        });
    };

    if (dto == null)
        return (<div className="editContainer">Laden...</div>);

    return (
        <div className="editContainer">
            <Toast ref={toast} />
            <h1>{title}</h1>
            <form onSubmit={SaveDto} autoComplete="off">
                <div className="fields">
                    <div className="field">
                        <label htmlFor="txtFirstname">Voornaam:</label>
                        <input
                            id="txtFirstname"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            defaultValue={dto.firstname}
                            onChange={(e) => setDto({ ...dto, firstname: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtSurname">Achternaam:</label>
                        <input
                            id="txtSurname"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            defaultValue={dto.surname}
                            onChange={(e) => setDto({ ...dto, surname: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtStreet">Straat:</label>
                        <input
                            id="txtStreet"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            defaultValue={dto.street}
                            onChange={(e) => setDto({ ...dto, street: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtHousenumber">Huisnummer:</label>
                        <input
                            id="txtHousenumber"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            defaultValue={dto.housenumber}
                            onChange={(e) => setDto({ ...dto, housenumber: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtHousenumberAddition">Huisnummer toevoeging:</label>
                        <input
                            id="txtHousenumberAddition"
                            type="text"
                            defaultValue={dto.housenumberaddition}
                            onChange={(e) => setDto({ ...dto, housenumberaddition: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtZipcode">Postcode:</label>
                        <input
                            id="txtZipcode"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            defaultValue={dto.zipcode}
                            onChange={(e) => setDto({ ...dto, zipcode: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtCity">Plaats:</label>
                        <input
                            id="txtCity"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            defaultValue={dto.city}
                            onChange={(e) => setDto({ ...dto, city: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="uplDeathCertificate">Akte van overlijden:</label>
                        <input type="file" accept=".pdf" onChange={HandleFileChange} /><br />
                        {dto.hasDeathcertificate ?
                            <img src="/images/icon-pdf.svg" onClick={DownloadDeathCertificate} className="downloadIcon" />
                            : <></>
                        }
                    </div>
                </div>
                <div className="buttons">
                    <input type="submit" value="Opslaan" />
                    <Link to='..'>
                        <input type="submit" value="Annuleren" />
                    </Link>
                </div>
            </form>
        </div>
    );
}

export default UserFileItemForPartner;