import baseService from "./BaseService";

const Update = (id, value) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put('/FormFill/update', {id: id, value: value})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) =>
            {
                reject(error);
            });
    });
};

const FormFillService = {
    Update
};

export default FormFillService;