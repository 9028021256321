import baseService from "./BaseService";

const Get = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/FormField/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const List = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/FormField/list', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const Create = (dto) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put('/FormField/create', dto)
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const Delete = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .delete(`/FormField/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const Update = (dto) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put('/FormField/update', dto)
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};


const FormFieldService = {
    Get,
    List,
    Create,
    Delete,
    Update
};

export default FormFieldService;