import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import ErrorHandler from '../../ErrorHandler';
import UserFileService from '../../../Service/UserFileService'
import StatusService from '../../../Service/StatusService';
import UploadHandler from '../../UploadHandler';
import UserFileAgencyService from '../../../Service/UserFileAgencyService';
import PowerOfAttorneyService from '../../../Service/PowerOfAttorneyService';

function Item()
{
    const remarkLength = 40;
    const toast = useRef(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const [title, setTitle] = useState(null);
    const [dto, setDto] = useState(null);
    const [statusList, setStatusList] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [showEvidenceDialog, setShowEvidenceDialog] = useState(false);
    const [dialogHeaderText, setDialogHeaderText] = useState("");
    const [dialogText, setDialogText] = useState("");
    const [dialogId, setDialogId] = useState(0);
    const [evidenceDto, setEvidenceDto] = useState(null);
    const [agencyList, setAgencyList] = useState(null);
    const [formData, setFormData] = useState(null);
    const [showFormDataDialog, setShowFormDataDialog] = useState(false);
    const [hasPOA, setHasPOA] = useState(false);

    const RefreshStatusList = () =>
    {
        // Get status records
        StatusService.Get(id).then((res) =>
        {
            setStatusList(res.data);

            PowerOfAttorneyService.GetFor(id).then((res) =>
            {
                setHasPOA(res.data ? true : false);
            }).catch(() =>
            {
                setHasPOA(false);
            });
        });
    };

    useEffect(() =>
    {
        if (id === undefined)
        {
            // Go back
            navigate('..');
        }
        else
        {
            setTitle("Inzien");

            // Get userfile
            UserFileService.Get(id).then((res) =>
            {
                setDto(res.data);
            });

            // Get userfileagencies (used for the form fields)
            UserFileAgencyService.ListFor(id).then((res) =>
            {
                setAgencyList(res.data);
            });

            RefreshStatusList();
        }
    }, []);

    const DownloadDeathCertificate = () =>
    {
        // Download death certificate
        UserFileService.DeathDertificate(dto.id).then((res) =>
        {
            // Create a new blob object
            const objUrl = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = objUrl;
            link.target = "_blank";
            link.setAttribute('download', `Akte van overlijden - ${dto.id}.pdf`);
            document.body.appendChild(link);
            link.click();
        });
    };

    const DownloadPowerOfAttorney = () =>
    {
        PowerOfAttorneyService.Evidence(dto.id).then((res) =>
        {
            // Create a new blob object
            const objUrl = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
            const link = document.createElement('a');
            link.href = objUrl;
            link.target = "_blank";
            link.setAttribute('download', `Volmacht - ${dto.id}.pdf`);
            document.body.appendChild(link);
            link.click();
        });
    };

    const ShowRemarks = (e) =>
    {
        // Lookup item
        const id = e.target.getAttribute("data-status-id");
        const status = statusList.find((item) => item.id == id);

        if (status !== undefined)
        {
            setDialogId(id);
            // Set dialog text
            setDialogHeaderText("Openstaande acties: " + status.agency_name);
            setDialogText(status.remarks);
            // Show dialog
            setShowDialog(true);
        }
    };

    const OpenEvidence = (e) =>
    {
        // Lookup item
        const id = e.target.getAttribute("data-status-id");

        // Request file
        StatusService.Evidence(id).then((res) =>
        {
            // Create a new blob object
            const objUrl = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement('a');
            link.href = objUrl;
            link.target = "_blank";
            link.setAttribute('download', 'Bewijsstuk.pdf');
            document.body.appendChild(link);
            link.click();
        });
    };

    const UploadFile = (e) =>
    {
        const id = e.target.getAttribute("data-status-id");
        const status = statusList.find((item) => item.id == id);

        if (status !== undefined)
        {
            // Store dialog id
            setDialogId(id);
            // Show dialog
            setShowEvidenceDialog(true);
        }
    }

    const UpdateStatus = (dto) =>
    {
        return new Promise((resolve, reject) =>
        {
            StatusService.Update(dto).then((res) => 
            {
                // Update succeeded, refresh status list
                RefreshStatusList();
                resolve();
            }).catch((err) =>
            {
                ErrorHandler.ToastErrors(toast, 'Er zijn een of meerdere fouten opgetreden bij het opslaan: ', err);
                reject();
            });
        });
    };

    const HideRemarkDialog = () =>
    {
        // Close popup
        setShowDialog(false);
        // Clear values
        setDialogHeaderText("");
        setDialogText("");
        setDialogId(0);
    }

    const HideEvidenceDialog = () =>
    {
        // Close popup
        setShowEvidenceDialog(false);
        // Clear values
        setEvidenceDto(null);
        setDialogId(0);
    }

    const SaveRemarks = () =>
    {
        // Get current item
        const status = statusList.find((item) => item.id == dialogId);
        if (status !== undefined)
        {
            status.remarks = dialogText;
            UpdateStatus(status).then(() =>
            {
                // On success, close dialog
                HideRemarkDialog();
            });
        }
    };

    const HandleEvidenceUploadChange = (e) =>
    {
        if (e.target.files)
        {
            UploadHandler.GetAttachmentDto(e.target.files[0]).then((res) =>
            {
                // Store in state
                setEvidenceDto(res);
            });
        }
    };

    const UploadEvidence = () =>
    {
        // Get current item
        const status = statusList.find((item) => item.id == dialogId);
        if (status !== undefined)
        {
            status.evidence = evidenceDto;
            UpdateStatus(status).then(() =>
            {
                // On success, close dialog
                HideEvidenceDialog();
            });
        }
    };

    const HasFormData = (id) =>
    {
        return agencyList.find((item) => item.id == id) !== undefined;
    }

    const ShowFormData = (e) =>
    {
        // Lookup item
        const id = e.target.getAttribute("data-userfileagency-id");
        const item = agencyList.find((item) => item.id == id);

        if (item.fields !== undefined && item.fields !== null && item.fields.length > 0)
        {
            // Set data
            setFormData(item);
            // Show dialog
            setShowFormDataDialog(true);
        }
    };

    const HideFormDataDialog = () =>
    {
        // Hide dialog
        setShowFormDataDialog(false);
        // Remove data
        setFormData(null);
    };

    const FormDataHeaderText = () =>
    {
        return "Formulier gegevens: " + formData?.agency;
    };

    const FormDataRenderFields = () =>
    {
        if (formData === null || formData.fields === null || formData.fields.length === 0)
            return "nodata";

        return (
            <>
            {formData.fields.map((field, fieldindex) =>
                {
                    return (
                        <div key={fieldindex} className="mb-5">
                            <div className="selectbox-label"><label htmlFor={`fld${field.id}`}>{field.name}</label></div>
                            <div className="inputtext">{RenderField(field, formData)}</div>
                            <div className="clearboth"></div>
                        </div>
                    );
                })
                }
            </>
        );
    };

    // Render the given field as the correct HTML field
    const RenderField = (field, agency) =>
    {
        switch (field.field_type)
        {
            case 2:
                return (<input key={field.id} data-agency-id={agency.id} data-field-id={field.id} id={`fld${field.id}`} disabled={true} readOnly={true}
                    type="checkbox" checked={field.value !== null && field.value !== ""} />);
                break;
            case 1:
            default:
                return (<input key={field.id} data-agency-id={agency.id} data-field-id={field.id} id={`fld${field.id}`} disabled={true} readOnly={true}
                    type="text" defaultValue={field.value ?? ""} />);
                break;
        }
    };

    if (title === null || dto === null || statusList === null || agencyList === null)
        return (<div className="editContainer">Laden...</div>);

    return (
        <>
            <div className="editContainer">
                <Toast ref={toast} />
                <h1>{title}</h1>

                <div className="fields">
                    <div className="fieldset">
                        <div className="field">
                            <label>Voornaam overledene:</label>
                            <span>{dto.firstname}</span>
                        </div>
                        <div className="field">
                            <label>Achternaam overledene:</label>
                            <span>{dto.surname}</span>
                        </div>
                        <div className="field">
                            <label>Akte van overlijden:</label>
                            <span>{dto.hasDeathcertificate ? <img src="/images/icon-pdf.svg" onClick={DownloadDeathCertificate} className="downloadIcon" /> : <></>}</span>
                        </div>
                        <div className="field">
                            <label>Volmacht:</label>
                            <span>{hasPOA ? <img src="/images/icon-pdf.svg" onClick={DownloadPowerOfAttorney} className="downloadIcon" /> : <></>}</span>
                        </div>
                    </div>
                    <div className="fieldset">
                        <div className="field">
                            <label>Adres:</label>
                            <span>{dto.street} {dto.housenumber}{dto.housenumberaddition}</span>
                        </div>
                        <div className="field">
                            <label>Postcode:</label>
                            <span>{dto.zipcode}</span>
                        </div>
                        <div className="field">
                            <label>Plaats:</label>
                            <span>{dto.city}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <section>
                    <div className="dashboard-statusoverzicht">Statusoverzicht</div>
                    <div className="wrapper-statusoverzichtbox">
                        <div className="dashboard-status">
                            <div className="wrapper-statusoverzicht">
                                <div>Organisatie</div>
                                <div>Geinformeerd
                                </div>
                                <div>Bevestiging
                                </div>
                                <div>Bewijsstuk
                                </div>
                                <div>Geen reactie
                                </div>
                                <div>Niet gevonden
                                </div>
                                <div>Openstaande acties
                                </div>
                            </div>
                            {statusList.map((status, statusIndex) => (
                                <div className="wrapper-statusoverzicht-detail fields" key={statusIndex}>
                                    <div className="field">
                                        {HasFormData(status.userfileagency_id) ?
                                            (<a onClick={ShowFormData} data-userfileagency-id={status.userfileagency_id}>{status.agency_name}</a>) :
                                            status.agency_name
                                        }
                                    </div>
                                    <div className="aligncenter">
                                        <div className="field">
                                            <input type="checkbox" name="geinformeerd" defaultChecked={status.informed} data-status-id={status.id} onChange={(e) => UpdateStatus({ ...status, informed: e.target.checked })} />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>
                                    <div className="aligncenter">
                                        <div className="field">
                                            <input type="checkbox" name="bevestigd" defaultChecked={status.confirmed} data-status-id={status.id} onChange={(e) => UpdateStatus({ ...status, confirmed: e.target.checked })} />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>
                                    <div className="aligncenter">
                                        <img src="/images/upload-solid.svg" className="middle" onClick={UploadFile} data-status-id={status.id} className="uploadIcon" />
                                        {status.evidence.filename != null && (<img src="/images/icon-pdf.svg" className="middle" onClick={OpenEvidence} data-status-id={status.id} className="downloadIcon" />)}
                                    </div>
                                    <div className="aligncenter">
                                        <div className="field">
                                            <input type="checkbox" name="geenreactie" defaultChecked={status.no_response} data-status-id={status.id} onChange={(e) => UpdateStatus({ ...status, no_response: e.target.checked })} />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>
                                    <div className="aligncenter">
                                        <div className="field">
                                            <input type="checkbox" name="gevonden" defaultChecked={status.not_found} data-status-id={status.id} onChange={(e) => UpdateStatus({ ...status, not_found: e.target.checked })} />
                                            <span className="checkmark"></span>
                                        </div>
                                    </div>
                                    <div>
                                        {status.remarks?.length > remarkLength ?
                                            (<>{status.remarks.substring(0, remarkLength)}... </>) :
                                            status.remarks
                                        }
                                        {status.remarks?.length > 0 ? (<br />) : <></> }
                                        <a className="leesmeer" data-status-id={status.id} onClick={ShowRemarks} title="toevoegen">Bewerken</a>
                                    </div>
                                </div>
                            ))}
                            <Dialog header={dialogHeaderText} visible={showDialog} onHide={HideRemarkDialog}
                                style={{ width: '75vw' }}>
                                <textarea className="dossierStatus" defaultValue={dialogText} onChange={(e) => setDialogText(e.target.value)}></textarea>
                                <div className="buttons">
                                    <input type="submit" name="" value="Opslaan" onClick={SaveRemarks} />
                                </div>
                            </Dialog>

                            <Dialog header="Uploaden bewijsstuk" visible={showEvidenceDialog} onHide={HideEvidenceDialog}
                                style={{ width: '75vw' }}>
                                <input type="file" accept=".pdf" onChange={HandleEvidenceUploadChange} />
                                <div className="buttons">
                                    <input type="submit" name="" value="Opslaan" onClick={UploadEvidence} />
                                </div>
                            </Dialog>

                            <Dialog header={FormDataHeaderText} visible={showFormDataDialog} onHide={HideFormDataDialog}
                                style={{ width: '75vw' }}>
                                <div>
                                    <FormDataRenderFields />
                                </div>
                            </Dialog>
                        </div>
                    </div>
                </section>


            </div>
        </>
    );
}

export default Item;