import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { ListBox } from 'primereact/listbox';
import { Checkbox } from 'primereact/checkbox';
import ErrorHandler from '../../ErrorHandler';
import FormService from '../../../Service/FormService'
import FormFieldService from '../../../Service/FormFieldService';
import AgencyService from '../../../Service/AgencyService';

function Item()
{
    const toast = useRef(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const [title, setTitle] = useState(null);
    const [dto, setDto] = useState(null);
    const [agencyList, setAgencyList] = useState(null);
    const [groupedAgencyList, setGroupedAgencyList] = useState(null);
    const [linkedAgencies, setLinkedAgencies] = useState(null);

    // Sub list
    const [selectedDto, setSelectedDto] = useState(null);
    const [refreshTrigger, setRefreshTrigger] = useState(false);

    // Check for toast message
    const { state } = useLocation();
    useEffect(() =>
    {
        if (state !== null)
        {
            let { toastParams } = state;
            if (toast.current !== null && toastParams !== null && toastParams !== undefined)
            {
                toast.current.show(toastParams);
                // Clear state
                delete state.toastParams;
            }
        }

    }, [refreshTrigger, toast.current, dto]);

    useEffect(() =>
    {
        if (id === undefined)
        {
            // New item
            setTitle("Toevoegen");
            setDto({});
        }
        else
        {
            setTitle("Bewerken");
            FormService.Get(id).then((res) =>
            {
                setDto(res.data);
                setLinkedAgencies(res.data.linkedAgencies);
            });

            // Get the latest version
        }
    }, [refreshTrigger]);

    useEffect(() =>
    {
        // Get full list of agencies
        AgencyService.List().then((res) =>
        {
            setAgencyList(res.data);
        });
    }, []);

    useEffect(() =>
    {
        if (agencyList === null || linkedAgencies === null)
            return;

        // Create a new list of selected and non selected items
        setGroupedAgencyList([
            {
                name: 'Geselecteerd',
                items: agencyList.filter((item) => linkedAgencies.indexOf(item.id) > -1)
            },
            {
                name: 'Beschikbaar',
                items: agencyList.filter((item) => linkedAgencies.indexOf(item.id) === -1)
            },
        ]);

    }, [linkedAgencies, agencyList]);

    const showNothingSelected = () => 
    {
        toast.current.show({ severity: 'error', summary: 'Fout', detail: 'Er is geen veld geselecteerd' });
    }

    // Save the current dto
    const saveDto = async (e) =>
    {
        // Prevent default submit behaviour
        e.preventDefault();

        let saveCallback = null;
        if (dto.id === null || dto.id === undefined)
            saveCallback = FormService.Create(dto);
        else
            saveCallback = FormService.Update(dto);

        saveCallback.then((res) =>
        {
            navigate('..', { state: { toastParams: { severity: 'success', summary: 'Opgeslagen', detail: 'Het sjabloon is opgeslagen' } } });
        })
        .catch((err) =>
        {
            ErrorHandler.ToastErrors(toast, 'Er zijn een of meerdere fouten opgetreden bij het opslaan: ', err);
        });
    };

    const Finalize = (e) =>
    {
        // Prevent default submit behaviour
        e.preventDefault();

        FormService.Finalize(dto.id).then(() =>
        {
            navigate('..', { state: { toastParams: { severity: 'success', summary: 'Opgeslagen', detail: 'Het sjabloon is definitief gemaakt' } } });
        })
        .catch((err) =>
        {
            ErrorHandler.ToastErrors(toast, 'Er zijn een of meerdere fouten opgetreden definitief maken: ', err);
        });
    };

    // Load edit 
    const editDto = () =>
    {
        if (selectedDto === null)
            showNothingSelected();
        else
            navigate(`./${selectedDto.id}`);
    };

    // Ask for delete confirmation
    const confirmDelete = (accept, reject) =>
    {
        confirmDialog({
            message: 'Weet je zeker dat je dit veld wilt verwijderen?',
            header: 'Bevestiging',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Ja',
            rejectLabel: 'Nee',
            accept,
            reject
        });
    };

    // Delete an item
    const deleteDto = () =>
    {
        if (selectedDto === null)
            showNothingSelected();
        else
        {
            // Ask for confirmation
            confirmDelete(
                () =>
                {
                    // Do the delete call
                    FormFieldService.Delete(selectedDto.id).then(() =>
                    {
                        // Show message 
                        toast.current.show({ severity: 'success', summary: 'Verwijderd', detail: 'Het veld is succesvol verwijderd' });
                        setRefreshTrigger(!refreshTrigger);
                    }).
                    catch((err) =>
                    {
                        ErrorHandler.ToastErrors(toast, 'Er zijn een of meerdere fouten opgetreden bij het verwijderen: ', err);
                    });
                },
                () =>
                {
                }
            );
        }
    };

    const fieldTypeBodyTemplate = (rowData) =>
    {
        switch (rowData.fieldType)
        {
            case 2:
                return "Checkbox";
                break;
            case 1:
            default:
                return "Tekst";
                break;
        }
    };

    const UpdateFieldOrder = (fields) =>
    {
        // Loop through fields, check if sortorder is changed vs original value
        for (let i = 0,  order = 1; i < fields.length ;i++, order++)
        {
            // Check if sortorder is changed, if so, post to API
            const dtItem = dto.fields.find((item) => item.id === fields[i].id);
            if (dtItem.sortOrder != order)
            {
                dtItem.sortOrder = order;
                FormFieldService.Update(dtItem).then((res) =>
                {
                    setRefreshTrigger(!refreshTrigger);
                });

                // After first match, we can break
                break;
            }

            order++;
        }        
    };

    const SaveLinkedAgencies = (e) =>
    {
        // Prevent default behaviour
        e.preventDefault();

        // Update linked agencies
        FormService.LinkAgencies({ formID: dto.id, agencyIDs: linkedAgencies }).then((res) =>
        {
            toast.current.show({ severity: 'success', summary: 'Opgeslagen', detail: 'Het koppeling met instanties is opgeslagen.' });
        })
        .catch((err) =>
        {
            ErrorHandler.ToastErrors(toast, 'Er zijn een of meerdere fouten opgetreden bij het opslaan: ', err);
        });
    };

    const agencySelectTemplate = (option) =>
    {
        return <div className="flex align-items-center">
            <div className="innerCheckbox"><Checkbox checked={linkedAgencies.indexOf(Number.parseInt(option.id)) > -1}></Checkbox></div>
            <div>{option.name}</div>
        </div>
    }

    const agencyGroupTemplate = (option) =>
    {
        return <div className="flex align-items-center">
            <div><b>{option.name}</b></div>
        </div>
    };

    if (title === null || dto === null)
        return (<div className="editContainer">Laden...</div>);

    return (
        <>
            <div className="editContainer">
                <Toast ref={toast} />
                <h1>{title}</h1>
                <form onSubmit={saveDto} autoComplete="off">
                    <div className="fields">
                        <div className="field">
                            <label htmlFor="txtName">Naam:</label>
                            <input
                                id="txtName"
                                    type="text"
                                    required={true}
                                    onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                                    onInput={e => e.target.setCustomValidity("")}
                                    title="Dit veld is verplicht"
                                    defaultValue={dto.name}
                                    onChange={(e) => setDto({ ...dto, name: e.target.value })}
                            />
                        </div>
                    </div>
                    <div className="buttons">
                        {dto.isConceptVersion ? (<input type="submit" value="Definitief maken" onClick={Finalize} />) : <></>}
                        <input type="submit" value="Opslaan" />
                        <Link to='..'>
                            <input type="submit" value="Annuleren" />
                        </Link>
                    </div>
                </form>
            </div>
            {dto.id !== undefined ?
                <>
                    <br />
                    <div>
                        <h2>Velden</h2>
                        <ConfirmDialog />
                        <div className="overview">
                            <DataTable
                                value={dto.fields}
                                paginator={true}
                                paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                                currentPageReportTemplate="{first} - {last} van {totalRecords}"
                                rowsPerPageOptions={[10, 20, 50]}
                                size="small"
                                dataKey="id"
                                loading={dto.fields === null}
                                selectionMode="single"
                                rows={10}
                                responsiveLayout="scroll"
                                selection={selectedDto}
                                onSelectionChange={(e) => { setSelectedDto(e.value); }}
                                emptyMessage="Geen resultaten gevonden."
                                reorderableRows onRowReorder={(e) => UpdateFieldOrder(e.value)}
                            >
                                <Column rowReorder style={{ width: '3rem' }} />
                                <Column field="name"
                                    header="Naam"
                                    showFilterMenu={false}
                                    style={{ minWidth: '12rem' }} />
                                <Column field="fieldType"
                                    header="Type"
                                    filter={false}
                                    body={fieldTypeBodyTemplate}
                                    style={{ minWidth: '12rem' }} />
                            </DataTable>
                        </div>
                        <div className="buttons">
                            <Link to='./toevoegen'>
                                <input type="submit" value="Toevoegen" />
                            </Link>
                            <input type="submit" value="Bewerken" onClick={editDto} />
                            <input type="submit" value="Verwijderen" onClick={deleteDto} />
                        </div>
                    </div>
                    <br />
                    <div>
                        <h2>Instanties</h2>
                        <ListBox
                            multiple
                            filter
                            value={linkedAgencies}
                            onChange={(e) => { setLinkedAgencies(e.value); }}
                            options={groupedAgencyList}
                            optionValue="id"
                            optionLabel="name"
                            optionGroupLabel="name"
                            optionGroupChildren="items"
                            optionGroupTEmplate={agencyGroupTemplate}
                            className="maxInstances"
                            itemTemplate={agencySelectTemplate}
                        />
                    </div>
                    <div className="buttons">
                        <input type="submit" value="Opslaan" onClick={SaveLinkedAgencies} />
                    </div>
                </>:
                <></>
            }
        </>
    );
}

export default Item;