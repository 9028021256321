import React from "react";
import Header from '../Components/Header/Header';
import DashboardMain from '../Components/Dashboard/DashboardMain/DashboardMain';
import DashboardStatus from '../Components/Dashboard/DashboardStatus/DashboardStatus';
import DashboardOrganizations from '../Components/Dashboard/DashboardOrganizations/DashboardOrganizations';
import Footer from '../Components/Footer/Footer';


function DashboardPage()
{
    return (
        <div id="upd-main">
            <Header />
            <main>
                <div className="dashboard-wrapper">
                    <DashboardMain />
                    <DashboardStatus />
                    <div className="dashboard-selecteer">Selecteer de organisaties die u wilt informeren</div>
                    <p>
                        De gegevens hoeven niet in 1 keer ingevuld te worden, de organisaties worden pas ingelicht als u de opdracht aan ons verzend.<br />
                        Uw account is 9 maanden actief, waardoor u ook achteraf nog organisaties kan toevoegen.
                    </p>

                    <DashboardOrganizations />
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default DashboardPage;