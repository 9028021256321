function ConvertBusinessMsg(msg)
{
    let message = []
    if (msg.response === undefined)
    {
        message.push({
            type: 1,
            message: String(msg),
        })
        return message;
    // If the data is an array and the array is bigger than 1
    }
    else if ((Array.isArray(msg.response.data)) && (msg.response.data.length > 0))
    {
        // Loop through the array to get all the errors
        var i;
        for (i = 0; i < msg.response.data.length; i++)
        {
            if (typeof msg.response.data[i] === 'object')
                message.push({
                    type: msg.response.data[i].type,
                    message: msg.response.data[i].message,
                });
            else
                message.push({
                    message: msg.response.data[i],
                });
        }
        return message;
    }
    else
    {
        if (typeof msg.response.data === 'object')
            // set the message
            message.push({
                type: 1,
                message: msg.response.data.title,
            })
        else
            // set the message
            message.push({
                type: 1,
                message: msg.response.data,
            });
        return message;
    }
}

function ToastErrors(toast, msg, err)
{
    const errArray = ConvertBusinessMsg(err);

    if (errArray === undefined || errArray === null || errArray.length === 0)
        errArray.push({ message: "Onbekende fout opgetreden" });

    ToastErrorMessage(toast, <>{msg}<br /><ul>{errArray.map((e, idx) => <li key={idx}>{e.message}</li>)}</ul></>);
}

function ToastErrorMessage(toast, msg)
{
    toast.current.show({ severity: 'error', summary: 'Fout', detail: msg });
}

function PasswordComplexityText()
{
    return 'Het opgegeven wachtwoord is te zwak, het wachtwoord moet minimaal 8 karakters lang zijn, een hoofdletter, kleine letter en een speciaal teken zoals (?=.[!"#$%&()*+,-./:;<=>?@[\]^_`{|}~])) bevatten';
}

const ErrorHandler = {
    ConvertBusinessMsg,
    ToastErrors,
    ToastErrorMessage,
    PasswordComplexityText
};

export default ErrorHandler;