import React from "react";
import './Features.scss';

function Features()
{
    return (
         <div className="features-wrapper">
            <div className="features-item completed">
                <img src="/images/img_check.png" alt="check icon" />
                <div className="startpagina_features_desc"> 
                    <div className="startpagina-stepcheckbox_h3">
                        Stopt snel alle doorlopende<br />
                        kosten na overlijden
                    </div>
                    <div className="startpagina-stepcheckbox_h4">
                        Stop per direct onnodig doorlopende<br />
                        abonnementen, contracten en accounts.
                    </div>
                </div>
            </div>
            <div className="features-item">
                <img src="/images/img_check.png" alt="check icon" />
                <div className="startpagina_features_desc">
                    <div className="startpagina-stepcheckbox_h3">
                        Het bespaart u energie<br />
                        en tijd, en geeft rust
                    </div>
                    <div className="startpagina-stepcheckbox_h4">
                        U vult eenmalig de gegevens<br />
                        en documenten in.
                    </div>
                </div>
            </div>
            <div className="features-item">
                <img src="/images/img_check.png" alt="check icon" />
                <div className="startpagina_features_desc">
                    <div className="startpagina-stepcheckbox_h3">
                        Alles onder controle in<br />
                        een online overzicht
                    </div>
                    <div className="startpagina-stepcheckbox_h4">
                        Alle formulieren op &eacute;&eacute;n plek,<br />
                        in een duidelijk overzicht.
                    </div>
                </div>
            </div>
        </div>


            
    );
}

export default Features;