import React from "react";
import './Steps.scss';

function Steps()
{
    return (
    <div className="startpagina_main">
      <div className="item"></div>
      <div className="item"></div>
      <div className="item"></div>
      <div className="item"></div>
      <div className="item"></div>
    </div>
    );
}

export default Steps;