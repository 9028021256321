import React from "react";
import Header from '../Components/Header/Header';
import ForgotPassword from '../Components/ForgotPassword/ForgotPassword';

function ForgotPasswordPage()
{
    return (
          <div id="upd-main">
            <Header />
            <main>
                <ForgotPassword />
            </main>
          </div>
    );
}

export default ForgotPasswordPage;