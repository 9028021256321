import React from "react";
import './ForgotPassword.scss';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import UserService from '../../Service/UserService';

function ResetPassword()
{
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => { setPasswordShown(!passwordShown); }
    const navigate = useNavigate();

    const handleNewPassword = () =>
    {
        try
        {
            let urlElements = location.pathname.split('/');

            if (urlElements.length >= 4)
            {
                UserService.resetPassword(
                    parseInt(urlElements[2]), // userID
                    urlElements[3], // code
                    password
                ).then((res) =>
                {
                    if (res.status === 200)
                        navigate("/login", { replace: true, state: { message: "Je kunt nu inloggen met je nieuwe wachtwoord", password: password } });
                    else
                    {
                        setMessage(res.error.message);
                    }
                });
            }
        }
        catch (err)
        {
            if (err.status === 400)
            {
                setMessage(err.response.data);
            }
            else
            {
                setMessage(err.code);
            }
        }
    };

    return (
        <div className="wrapperresetpassword">
            <div id="MainContent_udpContainer">
                <div className="forgotpw-container">
                    <div className="forgotpw-header">
                        <div className="forgotpw-header-title">Wachtwoord opnieuw instellen</div>
                        <div className="clear"></div>
                    </div>
                    {message}
                    <div className="forgotpw-wrapper">
                        <span>Nieuw wachtwoord:</span>
                        <div className="login-field-holder">
                            <input
                                type={passwordShown ? "text" : "password"}
                                id="password"
                                className="login-field"
                                placeholder="Wachtwoord"
                                required
                                onChange={(e) => setPassword(e.target.value)}
                            >
                            </input>
                            <span className="password-toggle" onClick={togglePassword}>Toon</span>
                        </div>
                    </div>
                    <div className="forgotpw-buttons">
                        <div className="request-button">
                            <input type="submit" className="forgotpw-button-style" value="Aanvragen" onClick={handleNewPassword} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;