import React from "react";
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import PartnerOverview from '../Components/Management/PartnerOverview';


function PartnerPage()
{
    return (
        <div id="upd-main">
            <Header />
            <main>
                <div className="dashboard-wrapper">
                    <PartnerOverview />
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default PartnerPage;