import { Navigate } from "react-router-dom";
import PermissionService from "./PermissionService";

export const PrivateRoute = ({ children, permission }) =>
{
    let permissions = JSON.parse(sessionStorage.getItem("UserPermissions"));

    // Get the authenticator
    let isAuthenticated = sessionStorage.getItem("auth");

    // If the authenticator is not empty go to the page. If not, navigate to the login page.
    if (!isAuthenticated || permission === undefined)
        return isAuthenticated ? children : <Navigate to="/" />;

    if (PermissionService.hasPermission(permission))
        return children;

    // Default return value
    return <Navigate to="/401" />;
};
export default PrivateRoute;