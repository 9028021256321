import React from "react";
import './Organizations.scss';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AgencyService from "../../../Service/AgencyService";


function Organizations()
{
	const [agencyList, setAgencyList] = useState([]);
	useEffect(() =>
	{
		AgencyService.getAgencyList().then((res) =>
		{
			setAgencyList(res.data);
		});
	}, [])

	const handleAgencyChange = (e) =>
	{
		// Parse any JSON previously stored in checkedAgencies
		var existingEntries = [];
		if (localStorage.getItem("checkedAgencies") !== null && localStorage.getItem("checkedAgencies") !== undefined)
			existingEntries = JSON.parse(localStorage.getItem("checkedAgencies"));
		if (existingEntries == null)
			existingEntries = [];

		if (e.target.type === 'checkbox')
		{
			var entryId = parseInt(document.getElementById(e.target.id).id);
			var entryAgency = document.getElementById(e.target.id).value;
			var entryCategory = document.getElementById(e.target.id).dataset.category;
			var entry = {
				"id": entryId,
				"agency": entryAgency,
				"category": entryCategory
			};

			if (e.target.checked)
			{
				// Check if not yet added
				if (existingEntries.indexOf(entryId) < 0)
				{
					// Add to the item
					existingEntries.push(entryId);
					localStorage.setItem("checkedAgencies", JSON.stringify(existingEntries));
				}
			}
			else
			{
				// Get item index
				const idx = existingEntries.indexOf(entryId);
				if (idx > -1)
				{
					// Remove it
					existingEntries.splice(idx, 1);
					localStorage.setItem("checkedAgencies", JSON.stringify(existingEntries));
				}
			}
		}
	}

	const agencyChecked = (agency) =>
	{
		// FIXME: Should be optimized, a lot of reloading/parsing right now
		var existingEntries = [];
		if (localStorage.getItem("checkedAgencies") !== null && localStorage.getItem("checkedAgencies") !== undefined)
			existingEntries = JSON.parse(localStorage.getItem("checkedAgencies"));
		if (existingEntries == null)
			existingEntries = [];

		return existingEntries.indexOf(agency.id) > -1;
	};

    return (
		<section>
			<div className="cards">
				<div className="card">
					<div className="start-box1">
						<div className="container-box">
							{agencyList.map((agency, index) => (
								<div className="checkbox-outer" key={index}>
									<div className="field">
										<input
											type="checkbox"
											name="agencies"
											value={agency.name}
											data-category={agency.category}
											onChange={handleAgencyChange}
											id={agency.id}
											defaultChecked={agencyChecked(agency)}
										/>
										<span className="checkmark"></span>
									</div>
									<div className="field organization-field">
										<label htmlFor={agency.id}>{agency.name}</label>
									</div>
								</div>
								))}
						</div>
						<div className="meerorganize">+5000 meer organisaties</div>
					</div>
				</div>
			</div>
			<div className="btn-gaverder-width">
				<Link to="/registreren" className="btn-gaverder-link">
					<div className="btn-gaverder">Ga verder</div>
				</Link>
			</div>
		</section>
    );
}

export default Organizations;