import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import PartnerService from '../../../Service/PartnerService';
import ErrorHandler from '../../ErrorHandler';

function Overview()
{
    const toast = useRef(null);
    const navigate = useNavigate();
    const [dtoList, setDtoList] = useState(null);
    const [selectedDto, setSelectedDto] = useState(null);
    const [refreshTrigger, setRefreshTrigger] = useState(false);

    // Check for toast message
    const { state } = useLocation();
    useEffect(() =>
    {
        if (state !== null)
        {
            let { toastParams } = state;
            if (toast.current !== null && toastParams !== null && toastParams !== undefined)
            {
                toast.current.show(toastParams);
                // Clear state
                delete state.toastParams;
            }
        }

    }, [refreshTrigger, toast.current, dtoList]);

    useEffect(() =>
    {
        // Retrieve all the items
        PartnerService.List().then((res) =>
        {
            setDtoList(res.data);
        });
    }, [refreshTrigger]);

    // Show error toast, nothing selected
    const showNothingSelected = () => 
    {
        toast.current.show({ severity: 'error', summary: 'Fout', detail: 'Er is geen partner geselecteerd' });
    }

    // Load edit 
    const editDto = () =>
    {
        if (selectedDto === null)
            showNothingSelected();
        else
            navigate(`./${selectedDto.id}`);
    };

    // Ask for delete confirmation
    const confirmDelete = (accept, reject) =>
    {
        confirmDialog({
            message: 'Weet je zeker dat je deze partner wilt verwijderen?',
            header: 'Bevestiging',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Ja',
            rejectLabel: 'Nee',
            accept,
            reject
        });
    };

    // Delete an item
    const deleteDto = () =>
    {
        if (selectedDto === null)
            showNothingSelected();
        else
        {
            // Ask for confirmation
            confirmDelete(
                () =>
                {
                    // Do the delete call
                    PartnerService.Delete(selectedDto.id).then(() =>
                    {
                        // Show message 
                        toast.current.show({ severity: 'success', summary: 'Verwijderd', detail: 'De partner is succesvol verwijderd' });
                        setRefreshTrigger(!refreshTrigger);
                    }).
                    catch((err) =>
                    {
                        ErrorHandler.ToastErrors(toast, 'Er zijn een of meerdere fouten opgetreden bij het verwijderen: ', err);
                    });
                },
                () =>
                {
                }
            );
        }
    };

    const loginPageBodyTemplate = (rowData) =>
    {
        return `${window.location.protocol}//${window.location.host}/login/${rowData.hash}`;
    };

    if (dtoList === null)
        return (<div>Laden...</div>);

    return (
        <div>
            <ConfirmDialog />
            <Toast ref={toast} />
            <div className="overview">
                <DataTable
                    value={dtoList}
                    paginator={true}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="{first} - {last} van {totalRecords}"
                    rowsPerPageOptions={[10, 20, 50]}
                    size="small"
                    dataKey="id"
                    filterDisplay="row"
                    loading={dtoList === null}
                    selectionMode="single"
                    rows={10}
                    responsiveLayout="scroll"
                    selection={selectedDto}
                    onSelectionChange={(e) => { setSelectedDto(e.value); }}
                    emptyMessage="Geen resultaten gevonden.">
                    <Column field="companyName"
                        header="Bedrijfsnaam"
                        filter
                        filterMatchMode="contains"
                        showFilterMenu={false}
                        filterPlaceholder="Zoek op naam"
                        style={{ minWidth: '12rem' }} />
                    <Column field="hash"
                        header="Inlog pagina"
                        filter={false}
                        body={loginPageBodyTemplate}
                    />
                </DataTable>
            </div>
            <div className="buttons">
                <Link to='./toevoegen'>
                    <input type="submit" value="Toevoegen" />
                </Link>
                <input type="submit" value="Bewerken" onClick={editDto} />
                <input type="submit" value="Verwijderen" onClick={deleteDto} />
            </div>
        </div>
    );
}

export default Overview;