import baseService from "./BaseService";

const GetAll = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/UserFileAgency/list', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) =>
            {
                reject(error);
            });
    });
};

const Create = (agency_id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put('/UserFileAgency/create', { agencyID: agency_id })
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) =>
            {
                reject(error);
            });
    });
};

const Delete = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .delete(`/UserFileAgency/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) =>
            {
                reject(error);
            });
    });
};

const ListFor = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/UserFileAgency/list-for/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const UserFileAgencyService = {
    GetAll,
    Create,
    Delete,
    ListFor
};

export default UserFileAgencyService;