import React from "react";
import Header from '../Components/Header/Header';
import ResetPassword from '../Components/ForgotPassword/ResetPassword';

function ResetPasswordPage()
{
    return (
        <div id="upd-main">
            <Header />
            <main>
                <ResetPassword />
            </main>
        </div>
    );
}

export default ResetPasswordPage;