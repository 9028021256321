import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import ErrorHandler from '../../ErrorHandler';
import PartnerService from '../../../Service/PartnerService';
import UploadHandler from '../../UploadHandler';
import { passwordStrength } from 'check-password-strength';

function Item()
{
    const toast = useRef(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const [title, setTitle] = useState(null);
    const [dto, setDto] = useState(null);
    const [passwordRepeatShown, setPasswordRepeatShown] = useState(false);
    const togglePasswordRepeat = () => { setPasswordRepeatShown(!passwordRepeatShown); }
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => { setPasswordShown(!passwordShown); }

    useEffect(() =>
    {

        if (id === undefined)
        {
            // New item
            setTitle("Toevoegen");
            setDto({ user: {}, logo: {} });
        }
        else
        {
            navigate('..');
        }
    }, []);

    const handleLogoFileChange = (e) =>
    {
        if (e.target.files)
        {
            UploadHandler.GetAttachmentDto(e.target.files[0]).then((res) =>
            {
                setDto({ ...dto, logo: res });
            });
        }
    };

    // Save the current dto
    const saveDto = async (e) =>
    {
        // Prevent default submit behaviour
        e.preventDefault();

        // Password check
        if (dto.user.password !== dto.user.passwordRepeat)
        {
            ErrorHandler.ToastErrorMessage(toast, 'De opgegeven wachtwoorden komen niet overeen');

            return;
        }

        // Check password complexity
        if (passwordStrength(dto.user.password).value === 'Weak' || passwordStrength(dto.user.password).value === 'Too weak')
        {
            ErrorHandler.ToastErrorMessage(toast, ErrorHandler.PasswordComplexityText());
            return;
        }

        // Save partner and user
        PartnerService.Create(dto).then((res) =>
        {
            navigate('..', { state: { toastParams: { severity: 'success', summary: 'Opgeslagen', detail: 'De partner is opgeslagen' } }});
        })
        .catch((err) =>
        {
            ErrorHandler.ToastErrors(toast, 'Er zijn een of meerdere fouten opgetreden bij het opslaan: ', err);
        });
    };

    if (title === null || dto === null)
        return (<div className="editContainer">Laden...</div>);

    return (
        <div className="editContainer">
            <Toast ref={toast} />
            <h1>{title}</h1>
            <form onSubmit={saveDto} autoComplete="off">
                <div className="fields">
                    <div className="field">
                        <label htmlFor="txtCompanyName">Bedrijfsnaam:</label>
                        <input
                            id="txtCompanyName"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            onChange={(e) => setDto({ ...dto, companyname: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="uplLogo">Logo:</label>
                        <div className="field">
                            <label htmlFor="uplLogo">Logo:</label>
                            <input type="file" accept=".jpg,.jpeg" onChange={handleLogoFileChange} /> <br />
                            {dto.logo.file !== undefined && dto.logo.file !== null ?
                                <img className="imagepreview" src={`data:${dto.logo.mimetype};base64,${dto.logo.file}`} title={dto.logo.filename} />
                                : <></>
                            }
                        </div>
                    </div>
                    <div className="field">
                        <label htmlFor="txtFirstname">Voornaam:</label>
                        <input
                            id="txtFirstname"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            onChange={(e) => setDto({ ...dto, user: { ...dto.user, firstname: e.target.value }})}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtSurname">Achternaam:</label>
                        <input
                            id="txtSurname"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            onChange={(e) => setDto({ ...dto, user: { ...dto.user, surname: e.target.value } })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtStreet">Straat:</label>
                        <input
                            id="txtStreet"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            onChange={(e) => setDto({ ...dto, user: { ...dto.user, street: e.target.value } })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtHousenumber">Huisnummer:</label>
                        <input
                            id="txtHousenumber"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            onChange={(e) => setDto({ ...dto, user: { ...dto.user, housenumber: e.target.value } })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtHousenumberAddition">Huisnummer toevoeging:</label>
                        <input
                            id="txtHousenumberAddition"
                            type="text"
                            onChange={(e) => setDto({ ...dto, user: { ...dto.user, housenumberaddition: e.target.value } })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtZipcode">Postcode:</label>
                        <input
                            id="txtZipcode"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            onChange={(e) => setDto({ ...dto, user: { ...dto.user, zipcode: e.target.value } })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtCity">Plaats:</label>
                        <input
                            id="txtCity"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            onChange={(e) => setDto({ ...dto, user: { ...dto.user, city: e.target.value } })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtPhonenumber">Telefoonnummer:</label>
                        <input
                            id="txtPhonenumber"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            onChange={(e) => setDto({ ...dto, user: { ...dto.user, phonenumber: e.target.value } })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtEmail">Email:</label>
                        <input
                            id="txtEmail"
                            type="email"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            onChange={(e) => setDto({ ...dto, user: { ...dto.user, email: e.target.value } })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtPasssword">Wachtwoord:</label>
                        <div className="password-field-holder">
                            <input
                                id="txtPasssword"
                                type={passwordShown ? "text" : "password"}
                                required={true}
                                onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                                onInput={e => e.target.setCustomValidity("")}
                                title="Dit veld is verplicht"
                                onChange={(e) => setDto({ ...dto, user: { ...dto.user, password: e.target.value } })}
                            />
                            <span className="password-toggle" onClick={togglePassword}>Toon</span>
                        </div>
                    </div>
                    <div className="field">
                        <label htmlFor="txtPassswordRepeat">Herhaal wachtwoord:</label>
                        <div className="password-field-holder">
                            <input
                                id="txtPassswordRepeat"
                                type={passwordRepeatShown ? "text" : "password"}
                                required={true}
                                onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                                onInput={e => e.target.setCustomValidity("")}
                                title="Dit veld is verplicht"
                                onChange={(e) => setDto({ ...dto, user: { ...dto.user, passwordRepeat: e.target.value } })}
                            />
                            <span className="password-toggle" onClick={togglePasswordRepeat}>Toon</span>
                        </div>
                    </div>
                </div>
                <div className="buttons">
                    <input type="submit" value="Opslaan" />
                    <Link to='..'>
                        <input type="submit" value="Annuleren" />
                    </Link>
                </div>
            </form>
        </div>
    );
}

export default Item;