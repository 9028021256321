import { NavLink, Outlet } from 'react-router-dom';
import './Overview.scss';

function PartnerOverview()
{
    return (
        <div>
            <Outlet />
        </div>
    );
}


export default PartnerOverview;