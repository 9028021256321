import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import Select from 'react-select';
import ErrorHandler from '../../ErrorHandler';
import UserService from '../../../Service/UserService';
import { passwordStrength } from 'check-password-strength';

function Item()
{
    const toast = useRef(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const [title, setTitle] = useState(null);
    const [dto, setDto] = useState(null);
    const [passwordRepeatShown, setPasswordRepeatShown] = useState(false);
    const togglePasswordRepeat = () => { setPasswordRepeatShown(!passwordRepeatShown); }
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => { setPasswordShown(!passwordShown); }
    
    useEffect(() =>
    {
        if (id === undefined)
        {
            setTitle("Toevoegen");
            setDto({
                roleID: 2, // We can only add general admins
                active: true
            });
        }
        else
        {
            setTitle("Bewerken");
            UserService.Get(id).then((res) =>
            {
                setDto(res.data);
            });
        }
    }, []);

    // Save the current dto
    const saveDto = async (e) =>
    {
        // Prevent default submit behaviour
        e.preventDefault();

        // Check password, if filled we need to validate and post it
        if (dto.password !== undefined && dto.password !== null && dto.password !== "")
        {
            // Check if both passwords equal
            if (dto.password !== dto.passwordRepeat)
            {
                ErrorHandler.ToastErrorMessage(toast, 'De opgegeven wachtwoorden komen niet overeen');

                return;
            }

            // Check password complexity
            if (passwordStrength(dto.password).value === 'Weak' || passwordStrength(dto.password).value === 'Too weak')
            {
                ErrorHandler.ToastErrorMessage(toast, ErrorHandler.PasswordComplexityText());
                return;
            }
        }

        let saveCallback = null;
        if (dto.id === null || dto.id === undefined)
            saveCallback = UserService.Create(dto);
        else
            saveCallback = UserService.Update(dto);
        
        saveCallback.then((res) =>
        {
            navigate('..', { state: { toastParams: { severity: 'success', summary: 'Opgeslagen', detail: 'De gebruiker is opgeslagen' } }});
        })
        .catch((err) =>
        {
            ErrorHandler.ToastErrors(toast, 'Er zijn een of meerdere fouten opgetreden bij het opslaan: ', err);
        });
    };


    if (title === null || dto === null)
        return (<div className="editContainer">Laden...</div>);

    return (
        <div className="editContainer">
            <Toast ref={toast} />
            <h1>{title}</h1>
            <form onSubmit={saveDto} autoComplete="off">
                <div className="fields">
                    <div className="field">
                        <label htmlFor="cbActive">Actief:</label>
                        <input
                            id="cbActive"
                            type="checkbox"
                            checked={dto.active}
                            onChange={e => setDto({ ...dto, active: e.target.checked })}
                        />
                        <span className="checkmark"></span>
                    </div>
                    <div className="field">
                        <label htmlFor="ddlRole">Type:</label>
                        <Select
                            id="ddlRole"
                            required={true}
                            options={UserService.RoleList}
                            getOptionLabel={(option) => option.name}
                            getOptionValue={(option) => option.id}
                            placeholder="Selecteer een rol"
                            value={UserService.RoleList.find((element) => { return element.id == dto.roleID })}
                            onChange={(item) => setDto({ ...dto, roleID: item.id })}
                            isSearchable={true}
                            isMulti={false}
                            isDisabled={true}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtFirstname">Voornaam:</label>
                        <input
                            id="txtFirstname"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            defaultValue={dto.firstname}
                            onChange={(e) => setDto({ ...dto, firstname: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtSurname">Achternaam:</label>
                        <input
                            id="txtSurname"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            defaultValue={dto.surname}
                            onChange={(e) => setDto({ ...dto, surname: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtStreet">Straat:</label>
                        <input
                            id="txtStreet"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            defaultValue={dto.street}
                            onChange={(e) => setDto({ ...dto, street: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtHousenumber">Huisnummer:</label>
                        <input
                            id="txtHousenumber"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            defaultValue={dto.housenumber}
                            onChange={(e) => setDto({ ...dto, housenumber: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtHousenumberAddition">Huisnummer toevoeging:</label>
                        <input
                            id="txtHousenumberAddition"
                            type="text"
                            defaultValue={dto.housenumberaddition}
                            onChange={(e) => setDto({ ...dto, housenumberaddition: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtZipcode">Postcode:</label>
                        <input
                            id="txtZipcode"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            defaultValue={dto.zipcode}
                            onChange={(e) => setDto({ ...dto, zipcode: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtCity">Plaats:</label>
                        <input
                            id="txtCity"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            defaultValue={dto.city}
                            onChange={(e) => setDto({ ...dto, city: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtPhonenumber">Telefoonnummer:</label>
                        <input
                            id="txtPhonenumber"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            defaultValue={dto.phonenumber}
                            onChange={(e) => setDto({ ...dto, phonenumber: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtEmail">Email:</label>
                        <input
                            id="txtEmail"
                            type="email"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            defaultValue={dto.email}
                            onChange={(e) => setDto({ ...dto, email: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtPasssword">Wachtwoord:</label>
                        <div className="password-field-holder">
                            <input
                                id="txtPasssword"
                                type={passwordShown ? "text" : "password"}
                                required={dto.id === undefined}
                                onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                                onInput={e => e.target.setCustomValidity("")}
                                title={dto.id === undefined ? "Dit veld is verplicht" : ""}
                                onChange={(e) => setDto({ ...dto, password: e.target.value })}
                            />
                            <span className="password-toggle" onClick={togglePassword}>Toon</span>
                        </div>
                    </div>
                    <div className="field">
                        <label htmlFor="txtPassswordRepeat">Herhaal wachtwoord:</label>
                        <div className="password-field-holder">
                            <input
                                id="txtPassswordRepeat"
                                type={passwordRepeatShown ? "text" : "password"}
                                required={dto.id === undefined}
                                onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                                onInput={e => e.target.setCustomValidity("")}
                                title={dto.id === undefined ? "Dit veld is verplicht" : ""}
                                onChange={(e) => setDto({ ...dto, passwordRepeat: e.target.value })}
                            />
                            <span className="password-toggle" onClick={togglePasswordRepeat}>Toon</span>
                        </div>
                    </div>
                </div>
                <div className="buttons">
                    <input type="submit" value="Opslaan" />
                    <Link to='..'>
                        <input type="submit" value="Annuleren" />
                    </Link>
                </div>
            </form>
        </div>
    );
}

export default Item;