import React, { Component } from "react";
import Header from '../Components/Header/Header';
import Footer from '../Components/Footer/Footer';
import PaymentPolling from '../Components/Payment/PaymentPolling';

function PaymentPage()
{
    return (
        <div id="udp-main">
            <Header />
            <main>
                <PaymentPolling />
            </main>
            <Footer />
        </div>
    );
}

export default PaymentPage;