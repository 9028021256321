import React from "react";
import './StepsDescription.scss';

function StepsDescription()
{
    return (
        <div className="stepper-wrapper">
            <div className="stepper-item completed">
                <div className="step-counter">1</div>
                <div className="startpagina_step_desc"> Start met een nieuwe melding.
                    Begin met het selecteren van de instanties die u wilt informeren.
                </div>
            </div>
            <div className="stepper-item">
                <div className="step-counter">2</div>
                <div className="startpagina_step_desc">
                    Maak een account aan.
                    Ga verder met het selecteren van instanties.
                    Upload de akte van overlijden.
                </div>
            </div>
            <div className="stepper-item">
                <div className="step-counter">3</div>
                <div className="startpagina_step_desc">
                    Geef ons opdracht en we gaan aan de slag.
                    U kunt later nog nieuwe instanties toevoegen.
                </div>
            </div>
        </div>
    );
}

export default StepsDescription;