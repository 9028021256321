import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import HomePage from './Pages/HomePage';
import LoginPage from './Pages/LoginPage';
import RegisterPage from './Pages/RegisterPage';
import DashboardPage from './Pages/DashboardPage';
import ForgotPasswordPage from './Pages/ForgotPasswordPage';
import ResetPasswordPage from './Pages/ResetPasswordPage';
import TermsOfServicePage from './Pages/TermsOfService';
import DisclaimerPage from './Pages/DisclaimerPage';
import PrivacyPolicyPage from './Pages/PrivacyPolicyPage';
import PageNotFound from './Pages/404Page';
import PaymentPage from './Pages/PaymentPage';
import PaymentFailedPage from './Pages/PaymentFailedPage';
import ManagementPage from './Pages/ManagementPage';
import PartnerPage from './Pages/PartnerPage';
import * as Management from './Components/Management';

import './App.scss';
import { PrivateRoute } from "./Service/PrivateRoute";

function App()
{
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route index element={<HomePage />} />
                    <Route path="/login">
                        <Route index element={<LoginPage />} />
                        <Route path=":partnerHash" element={<LoginPage />}/>
                    </Route>
                    <Route path="*" element={<PageNotFound />} />
                    <Route path="/registreren" element={<RegisterPage />} />
                    <Route path="/ForgotPassword" element={<ForgotPasswordPage />} />
                    <Route path="/ResetPassword/:userID/:hash" element={<ResetPasswordPage />} />
                    <Route path="/algemene-voorwaarden" element={<TermsOfServicePage />} />
                    <Route path="/disclaimer" element={<DisclaimerPage />} />
                    <Route path="/privacyverklaring" element={<PrivacyPolicyPage />} />
                    <Route
                        path="/dashboard"
                        element={
                            <PrivateRoute permission='User'>
                                <DashboardPage />
                            </PrivateRoute>
                        }
                    />
                    <Route path="/beheer" element={
                        <PrivateRoute permission='Admin'>
                            <ManagementPage />
                        </PrivateRoute>
                    }>
                        <Route path="categorie">
                            <Route index element={
                                <PrivateRoute permission='Admin'>
                                    <Management.CategoryOverview />
                                </PrivateRoute>
                            }/>
                            <Route path="toevoegen" element={
                                <PrivateRoute permission='Admin'>
                                    <Management.CategoryItem />
                                </PrivateRoute>
                            }/>
                            <Route path=":id" element={
                                <PrivateRoute permission='Admin'>
                                    <Management.CategoryItem />
                                </PrivateRoute>
                            } />
                        </Route>
                        <Route path="instantie">
                            <Route index element={
                                <PrivateRoute permission='Admin'>
                                    <Management.AgencyOverview />
                                </PrivateRoute>
                            }/>
                            <Route path="toevoegen" element={
                                <PrivateRoute permission='Admin'>
                                    <Management.AgencyItem />
                                </PrivateRoute>
                            } />
                            <Route path=":id" element={
                                <PrivateRoute permission='Admin'>
                                    <Management.AgencyItem />
                                </PrivateRoute>
                            } />
                        </Route>
                        <Route path="formulier">
                            <Route index element={
                                <PrivateRoute permission='Admin'>
                                    <Management.FormOverview />
                                </PrivateRoute>
                            }/>
                            <Route path="toevoegen" element={
                                <PrivateRoute permission='Admin'>
                                    <Management.FormItem />
                                </PrivateRoute>
                            } />
                            <Route path=":id">
                                <Route index element={
                                    <PrivateRoute permission='Admin'>
                                        <Management.FormItem />
                                    </PrivateRoute>
                                } />
                                <Route path="toevoegen" element={
                                    <PrivateRoute permission='Admin'>
                                        <Management.FormFieldItem />
                                    </PrivateRoute>
                                } />
                                <Route path=":fieldId" element={
                                    <PrivateRoute permission='Admin'>
                                        <Management.FormFieldItem />
                                    </PrivateRoute>
                                } />
                            </Route> 
                        </Route>
                        <Route path="dossier">
                            <Route index element={
                                <PrivateRoute permission='Admin'>
                                    <Management.UserFileOverview />
                                </PrivateRoute>
                            }/>
                            <Route path=":id" element={
                                <PrivateRoute permission='Admin'>
                                    <Management.UserFileItem />
                                </PrivateRoute>
                             } />
                        </Route>
                        <Route path="partner">
                            <Route index element={
                                <PrivateRoute permission='Admin'>
                                    <Management.PartnerOverview />
                                </PrivateRoute>
                            }/>
                            <Route path="toevoegen" element={
                                <PrivateRoute permission='Admin'>
                                    <Management.PartnerNewItem />
                                </PrivateRoute>
                             } />
                            <Route path=":id" element={
                                <PrivateRoute permission='Admin'>
                                    <Management.PartnerItem />
                                </PrivateRoute>
                             } />
                        </Route>
                        <Route path="gebruiker">
                            <Route index element={
                                <PrivateRoute permission='Admin'>
                                    <Management.UserOverview />
                                </PrivateRoute>
                            }/>
                            <Route path="toevoegen" element={
                                <PrivateRoute permission='Admin'>
                                    <Management.UserItem />
                                </PrivateRoute>
                             } />
                            <Route path=":id" element={
                                <PrivateRoute permission='Admin'>
                                    <Management.UserItem />
                                </PrivateRoute>
                            } />
                        </Route>
                    </Route>
                    <Route path="/partner" element={
                            <PrivateRoute permission='Partner'>
                                <PartnerPage />
                            </PrivateRoute>
                        }>
                        <Route index element={
                            <PrivateRoute permission='Partner'>
                                <Management.UserFilePartnerOverview />
                            </PrivateRoute>
                        } />
                        <Route path="toevoegen" element={
                            <PrivateRoute permission='Partner'>
                                <Management.UserFileNewItem />
                            </PrivateRoute>
                        } />
                        <Route path=":id" element={
                            <PrivateRoute permission='Partner'>
                                <Management.UserFileItemForPartner />
                            </PrivateRoute>
                        } />
                    </Route>
                    <Route path="/betaling">
                        <Route path="mislukt" element={<PaymentFailedPage />} />
                        <Route path=":userFileId" element={<PaymentPage />} />
                    </Route>
                </Routes>
            </Router>
        </div>
    );
}

export default App;
