import baseService from "./BaseService";

// Get the list of permissions for user
const getPermissionListForUser = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/Permission/list-for-user', { })
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const isAuthenticated = () =>
{
    // Get the auth vars
    let varauth = sessionStorage.getItem("auth");

    return varauth !== null;
};

const hasPermission = (permission) =>
{
    // If not authenticated, we don't have the permission unless it's undefined
    if (!isAuthenticated() || permission === undefined)
        return isAuthenticated() ? true : false;

    // Get the permissions
    let permissions = JSON.parse(sessionStorage.getItem("UserPermissions"));

    var i;
    // For each permission
    if (permissions === undefined || permissions == null || permissions.length == 0)
        return "";

    // Lookup the permission in our array
    return permissions.find(item => item.name === permission) !== undefined;
};

const PermissionService = {
    getPermissionListForUser,
    isAuthenticated,
    hasPermission
};

export default PermissionService;