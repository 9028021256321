import React, { useEffect, useState } from "react";
import { Dialog } from 'primereact/dialog';
import useCollapse from 'react-collapsed';
import FormFillService from "../../../Service/FormFillService";
import UserFileService from "../../../Service/UserFileService";
import './DashboardOrganizationsRight.scss';


function DashboardOrganizationRight(props)
{
	const [showApproveDialog, setShowApproveDialog] = useState(false);
	const [approved, setApproved] = useState(false);
	const [expandedState, setExpandedState] = useState(null);

	useEffect(() =>
	{
		if (props.AgencyList !== null && expandedState === null)
		{
			// Create a array for the expanded state
			const stateList = [];

			props.AgencyList.map((agency) =>
			{
				stateList.push({ id: agency.id, state: FormHasFields(agency) })
			});

			setExpandedState(stateList);
		}
	}, [props.AgencyList]);

	const approvedChanged = (e) =>
	{
		setApproved(e.target.checked);
	};

	const ApproveItems = (e) =>
	{
		if (!approved)
			setShowApproveDialog(true);
		else 
		{
			// Get all non locked items (newly selected)
			let items = props.AgencyList.filter(item => !item.locked);

			// Get status
			const status = UserFileService.GetStatus();

			// Check if all filled in
			if (items === undefined
				|| items.length === 0
				|| items.find(item => !FormFilled(item)) !== undefined
				|| !status.dead_certificate_uploaded
				|| !status.power_of_attorney_signed
			)
				setShowApproveDialog(true);
			else
			{
				// Process items
				UserFileService.Process().then((res) =>
				{
					// Refresh current page
					window.location.reload(false);
				});
			}
		}
	};

	// Check if form has fields
	const FormHasFields = (agency) =>
	{
		return !(agency.fields === undefined || agency.fields === null || agency.fields.length === 0);
	}

	// Check if all the required fields of the form are filled
	const FormFilled = (agency) =>
	{
		// In case we don't have any fields
		if (agency.fields === undefined || agency.fields === null || agency.fields.length === 0)
			return true;

		// Loop through all the fields
		for (let i = 0; i < agency.fields.length; i++)
		{
			// Check if we have required fields, without value
			if (agency.fields[i].required && (agency.fields[i].value === null || agency.fields[i].value === ""))
				return false;

			// Check if we need to hide the following fields, if so, break the loop. No need to check the other fields
			if (agency.fields[i].hide_following && (agency.fields[i].value === null || agency.fields[i].value === ""))
				break;
		}

		// We've reached this point, no required fields are empty
		return true;
	};

	// Trigger on change of field (checked change on checkbox, blur on textbox)
	const FieldChange = (e) =>
	{
		let id = e.target.getAttribute("data-field-id");
		let value = "";
		if (e.target.type === 'checkbox')
		{
			if (e.target.checked)
				value = "1";
		}
		else
		{
			value = e.target.value;
		}
		
		// Update 
		FormFillService.Update(id, value).then((res) =>
		{
			props.RefreshData();
		});
	};

	// Render the given field as the correct HTML field
	const RenderField = (field, agency) =>
	{
		switch (field.field_type)
		{
			case 2:
				return (<input key={field.id} data-agency-id={agency.id} data-field-id={field.id} id={`fld${field.id}`} disabled={agency.locked} readOnly={agency.locked}
					type="checkbox" checked={field.value !== null && field.value !== ""} onChange={FieldChange} />);
				break;
			case 1:
			default:
				return (<input key={field.id} data-agency-id={agency.id} data-field-id={field.id} id={`fld${field.id}`} disabled={agency.locked} readOnly={agency.locked}
					type="text" defaultValue={field.value ?? ""} onBlur={FieldChange} onKeyDown={event => event.key === 'Enter' && FieldChange(event)} />);
				break;
		}
	};

	// Render all the fields
	const RenderFields = (agency) =>
	{
		let hideFollowing = false;
		if (agency.fields === undefined || agency.fields.length == 0)
			return (<div>Geen verdere informatie benodigd</div>);

		return (
			<>
				{agency.fields.map((field, fieldindex) =>
				{
					if (hideFollowing)
						return "";

					if (field.hide_following && (field.value === null || field.value === ""))
						hideFollowing = true;

					return (
						<div key={fieldindex}>
							<div className="selectbox-label"><label htmlFor={`fld${field.id}`}>{field.name}</label></div>
							<div className="inputtext">{RenderField(field, agency)}</div>
						</div>
					);
				})}
			</>
		);
	};

	function SectionCollapse(props)
	{
		const config = {
			defaultExpanded: props.defaultExpanded || expandedState.find((item) => item.id == props.agency.id)?.state || !FormFilled(props.agency),
			collapsedHeight: props.collapsedHeight || 0
		};
		const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);
		return (
			<div className="collapsible">
				<div className="header" {...getToggleProps({
					onClick: () =>
					{
						const item = expandedState.find((item) => item.id == props.agency.id);
						if (item !== undefined)
							item.state = !item.state;
						else
							expandedState.push({ id: props.agency.id, state: false });
					}
				})}>
					<div className="arrow">
						<img src={"/images/" + (isExpanded ? "arrow-up.png" : "arrow-down.png")} />
					</div>
				</div>
				<div {...getCollapseProps()}>
					<div className="content">
						{props.children}
					</div>
				</div>
			</div>
		);
	}

	// If we don't have the data yet from our parent component, show a loading text
	if (props === undefined || props.AgencyList === null || expandedState === null)
		return (
			<div className="col-right">
				Laden...
			</div>
		);

	// In case no agencies selected
	if (props.AgencyList.length == 0)
		return (
			<div className="col-right">
				Nog geen instanties aangevinkt.
			</div>
		);

	// Main render method
	return (
		<div className="col-right">
			{props.AgencyList.map((agency, index) => (
				<div className="dashboard-selectbox" key={index}>
					<div>
						<div className="boxleft collapsible">
							<input type="checkbox" disabled="disabled" checked={FormFilled(agency)} />
							{agency.agency}
							<br />
						</div>

						<br />
						<div className="clearboth"></div>
					</div>
					<SectionCollapse agency={agency}>
						{RenderFields(agency)}
					</SectionCollapse>
					
				</div>
			))}
			<div className="dashboard-box-akkoord">
				<span className="dashboard-groep dashboardTOS">
					<input type="checkbox" id="cbApprove" checked={approved} onChange={approvedChanged} />
				</span>
				
				<label htmlFor="cbApprove">Ik geef Nazorgdesk akkoord om de wijzigingen <br />
					door te geven aan alle geselecteerde organisaties.</label>
			</div>
			<button className="btn-dashboard2" onClick={ApproveItems}>Opdracht verzenden</button>
			<Dialog header="Niet alle gegevens zijn ingevoerd" style={{ width: '50vw' }}
				visible={showApproveDialog} onHide={() => setShowApproveDialog(false)}>
				<p>
					Om de opdracht te kunnen verzenden, dienen alle gegevens bij de instanties te zijn ingevoerd en
					dient het akkoord vinkje aangevinkt te zijn. <br />
					<br />
					Daarnaast moeten zowel de akte van overlijden aanwezig zijn, als de volmacht te zijn getekend.
				</p>
			</Dialog>
		</div>
	);
}

export default DashboardOrganizationRight;
