import React from "react";
import { Link } from "react-router-dom";
import './Footer.scss';

function Footer()
{
    return (
        <footer>
            <div className="grid-container">
                <div className="item2"><img src="/images/img_heefuvrag.jpg" alt="Heef u vragen?" /></div>
                <div className="item3"><strong>Heeft u vragen?</strong> Neem dan contact met mij op:</div>
                <div className="item5"><strong>Adriaan de Man</strong>
                    <span className="item5-1">
                        <img src="/images/icon_tel.jpg" alt="Telefoon" />
                        <Link to="tel:+310113853994" title="0113-854944">
                            0113-854944
                        </Link>
                    </span>
                    <span className="item5-2">
                        <img src="/images/icon_mail.jpg" className="middle" alt="e-mail" />
                        <Link to="mailto:&#105;&#110;&#102;&#111;&#64;&#110;&#97;&#122;&#111;&#114;&#103;&#100;&#101;&#115;&#107;&#46;&#110;&#108;">
                            info@nazorgdesk.nl
                        </Link>
                    </span>
                </div>
            </div>
            <div className="footer">
                <ul className="footer-list">
                    <li className="fontbold"><a href="https://www.nazorgdesk.nl/onewebmedia/Disclaimer.pdf">Disclaimer</a></li>
                    <li><a href="https://www.nazorgdesk.nl/onewebmedia/Algemene%20Voorwaarden%20voor%20volmachtgevers.pdf">Algemene voorwaarden</a></li>
                    <li><a href="https://www.nazorgdesk.nl/privacyverklaring">Privacyverklaring</a></li>
                    <li>&copy; {(new Date().getFullYear())} Nazorgdesk</li>
                    <li>Alle rechten voorbehouden</li>
                    <li className="fontbold">Concept &amp; realisatie : <a href="https://www.estrategy.nl" target="_blank" title="Estrategy B.V.">Estrategy</a></li>
                </ul>
            </div>
        </footer>
    );
}

export default Footer;