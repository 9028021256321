import React from "react";
import './ForgotPassword.scss';
import { useState } from 'react';
import UserService from '../../Service/UserService';

function ForgotPassword()
{
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const handlePasswordRequest = async (e) =>
    {
        e.preventDefault();
        try
        {
            UserService.forgotPasswordRequest(email).then((res) =>
            {
                if (res?.status === 200)
                {
                    setMessage(res.data[0]);
                }
                else
                {
                    setMessage("Onbekende fout opgetreden");
                }

            }).catch((err) =>
            {
                if (err.status === 404)
                    setMessage("Indien het e-mailadres bekend is, is er een e-mail verzonden");
                else
                {
                    setMessage("Onbekende fout opgetreden");
                }
            });
        }
        catch (err)
        {
            setMessage("Onbekende fout opgetreden");
        }
    };

    return (
        <div className="wrapperresetpassword">
            <div id="MainContent_udpContainer">
                <div className="forgotpw-container">
                    <div className="forgotpw-header">
                        <div className="forgotpw-header-title">Wachtwoord vergeten</div>
                        <div className="clear"></div>
                    </div>
                    {message}
                    <div className="forgotpw-email">
                        <span>Geef hieronder je e-mailadres op.<br />Nadat je op de knop aanvragen hebt geklikt ontvangt je een e-mail, met daarin een link waarmee het wachtwoord opnieuw ingesteld kan worden.</span>
                        <div className="forgotpw-wrapper">
                            <span>E-mailadres:</span>
                            <input
                                type="email"
                                id="email"
                                className="login-field"
                                placeholder="Vul je e-mailadres in"
                                required
                                onChange={(e) => setEmail(e.target.value)} />
                        </div>
                    </div>
                    <div className="forgotpw-buttons">
                        <div className="request-button">
                            <input type="submit" className="forgotpw-button-style" value="Aanvragen" onClick={handlePasswordRequest} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;