import React from "react";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../Components/Header/Header';
import Slider from '../Components/Home/Slider/Slider';
import Organization from '../Components/Home/Organizations/Organizations';
import Features from '../Components/Home/Features/Features';
import Footer from '../Components/Footer/Footer';
import Steps from "../Components/Home/Steps/Steps/Steps";
import StepsDescription from "../Components/Home/Steps/StepsDescription/StepsDescription";
import PermissionService from '../Service/PermissionService'

function HomePage()
{
    const navigate = useNavigate();
    useEffect(() =>
    {
        // Check if we are already authenticated
        if (PermissionService.isAuthenticated())
            // If so, navigate to login which will navigate to the correct page
            navigate('/login', { replace: true });
    });

    return (
        <div id="upd-main">
            <Header />
            <main>
                <section>
                    <Slider />
                    <Steps />
                    <StepsDescription />
                </section>
                <section>
                    <Organization />
                </section>
                <section>
                    <Features />
                </section>
            </main>
            <Footer />
        </div>
    );
}

export default HomePage;