import React, { useEffect, useRef } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useNavigate, useParams } from 'react-router-dom';
import UserFileService from "../../Service/UserFileService";

function PaymentPolling()
{
    const navigate = useNavigate();

    // Get ID from URL
    let { userFileId } = useParams();

    useInterval(async () =>
    {
        // Call api
        const response = await UserFileService.PollPayment(userFileId)
            .then((res) =>
            {
                navigate("/login");            
            })
            .catch((err) => 
            {
                if (err?.response?.status === 403)
                    navigate("/betaling/mislukt");
            });
    }, 2500);
    return (
        <div className="loadingwrapper aligncenter">
            <ProgressSpinner aria-label="Betaling verwerken" /> <br />
            Betaling verwerken..
        </div>
    );
}

export const useInterval = (callback, delay) =>
{

    const savedCallback = useRef();

    useEffect(() =>
    {
        savedCallback.current = callback;
    }, [callback]);


    useEffect(() =>
    {
        function tick()
        {
            savedCallback.current();
        }
        if (delay !== null)
        {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export default PaymentPolling;