import baseService from "../Service/BaseService";

const Get = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/User/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const List = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/User/list', {
                headers: {
                    accept: "application/json",
                    "x-api-version": "1.0",
                }
            })
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const Create = (dto) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put('/User/create', dto)
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const Delete = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .delete(`/User/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const Update = (dto) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put('/User/update', dto)
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Request new password
const forgotPasswordRequest = (email) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/User/forgot-password-request', { email },
                { }
            )
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const resetPassword = (userId, code, password) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put(
                '/User/reset-password', { userId, code, password },
                { }
            )
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const RoleList = [
    {
        id: 1,
        name: "Estrategy beheerder"
    },
    {
        id: 2,
        name: "Beheerder"
    },
    {
        id: 3,
        name: "Partner"
    },
    {
        id: 4,
        name: "Gebruiker"
    }
];

const UserService = {
    Get,
    List,
    Create,
    Delete,
    Update,
    forgotPasswordRequest,
    resetPassword,
    RoleList
};

export default UserService;