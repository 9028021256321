import React from "react";
import './RegisterContactPerson.scss';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from 'react';
import UserFileService from "../../../Service/UserFileService";
import { passwordStrength } from 'check-password-strength';
import ErrorHandler from '../../ErrorHandler';
import 'react-phone-number-input/style.css'
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input'

function RegisterContactPerson()
{
    const [firstname, setFirstname] = useState("");
    const [surname, setSurname] = useState("");
    const [street, setStreet] = useState("");
    const [housenumber, setHousenumber] = useState("");
    const [housenumberAddition, setHousenumberAddition] = useState("");
    const [zipcode, setZipcode] = useState("");
    const [city, setCity] = useState("");
    const [userFirstname, setUserFirstname] = useState("");
    const [userSurname, setUserSurname] = useState("");
    const [userStreet, setUserStreet] = useState("");
    const [userHousenumber, setUserHousenumber] = useState("");
    const [userHousenumberAddition, setUserHousenumberAddition] = useState("");
    const [userZipcode, setUserZipcode] = useState("");
    const [userCity, setUserCity] = useState("");
    const [userPhonenumber, setUserPhonenumber] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [userPassword, setUserPassword] = useState("");
    const [userRepeatPassword, setUserRepeatPassword] = useState("");
    const [TermsOfService, setTermsOfService] = useState("");
    const [agencies, setAgencies] = useState("");

    const [errorMessage, setErrorMessage] = useState("");
    const [confirmMessage, setConfirmMessage] = useState("");
    const navigate = useNavigate();
    const { state } = useLocation();
    const [intTelInputObj, setIntTelInputObj] = useState(null);

    useEffect(() =>
    {
        if (state !== null)
        {
            const { confirmedMessage } = state;
            setConfirmMessage(confirmedMessage);
        }
    }, []);


    useEffect(() =>
    {
        if (localStorage.getItem("checkedAgencies") !== null && localStorage.getItem("checkedAgencies") !== undefined)
        {
            var existingEntries = JSON.parse(localStorage.getItem("checkedAgencies"));
            setAgencies(existingEntries);
        }
    }, []);

    useEffect(() =>
    {
        setErrorMessage("");
    }, [firstname, surname, street, housenumber, housenumberAddition, zipcode, city, userFirstname, userSurname,
        userStreet, userHousenumber, userHousenumberAddition, userZipcode, userCity, userPhonenumber, userEmail,
        userPassword, TermsOfService]);

    const handleSubmit = async (e) =>
    {
        e.preventDefault();
        try
        {
            if (userPassword === userRepeatPassword)
            {

                // Check password complexity
                if (passwordStrength(userPassword).value === 'Weak' || passwordStrength(userRepeatPassword).value === 'Too weak')
                {
                    setErrorMessage(ErrorHandler.PasswordComplexityText());
                    return;
                }

                if (!isPossiblePhoneNumber(userPhonenumber))
                {
                    setErrorMessage("Ongeldig telefoonnummer opgegeven");
                    return;
                }

                const response = await UserFileService.CreateUserFileWithAgencies(
                    firstname, surname, street,housenumber, housenumberAddition, zipcode, city, userFirstname,
                    userSurname, userStreet, userHousenumber, userHousenumberAddition, userZipcode, userCity, userPhonenumber,
                    userEmail, userPassword, agencies
                );

                if (response?.status === 201)
                {
                    // Check payment link in response
                    if (response?.data !== undefined && response?.data.redirect_url !== undefined && response?.data.redirect_url !== null)
                    {
                        window.location.href = response?.data.redirect_url;
                        return null;
                    }
                    else
                        navigate("/login");
                }
                else
                {
                    // General error
                    setErrorMessage("Onbekende fout opgetreden");
                }
            }
            else
            {
                // Error handling in case passwords not equal?
                setErrorMessage("De opgegeven wachtwoorden zijn niet gelijk aan elkaar");
            }
        }
        catch (err)
        {
            if (!err?.response)
            {
                setErrorMessage("Server reageert niet");
            } else if (err.response?.status === 400)
            {
                const errArray = ErrorHandler.ConvertBusinessMsg(err);
                if (errArray === undefined || errArray === null || errArray.length === 0)
                    setErrorMessage("Er is een onbekende fout opgetreden.");
                else
                {
                    let errStr = "Er zijn een of meerdere fouten opgetreden:\n\r"
                    errArray.map((errItem) =>
                    {
                        errStr += "- " + errItem.message + "\n\r";
                    });
                    setErrorMessage(errStr);
                }
            } else if (err.response?.status === 401)
            {
                setErrorMessage("Geen authorisatie");
            }
            else if (err.response?.status === 422)
            {
                setErrorMessage("Server kan de aanvraag niet verwerken");
            }
            else
            {
                setErrorMessage("Account aanmaken mislukt");
            }
        }
    };

    return (
        /*------- Registeren Gegevens contactpersoon----*/
        <>
            <form onSubmit={handleSubmit}>
            <div className="box">
            <div className="registier-mainright">
                <div className="register-contactperson">

                    <h1>Registeren</h1>
                    <h2>Gegevens contactpersoon</h2>
                    <div className="register-contactperson2">
                        <div>
                            <label>Voornaam*</label>
                            <input
                                type="text"
                                id="userVoornaam"
                                name="voornaam"
                                placeholder="Vul uw voornaam in"
                                required
                                onChange={(e) => setUserFirstname(e.target.value)}
                                 />
                            <label>Achternaam*</label>
                            <input
                                type="text"
                                id="userAchternaam"
                                name="achternaam"
                                placeholder="Vul uw achternaam"
                                required
                                onChange={(e) => setUserSurname(e.target.value)}

                                 />

                            <label>Straat*</label>
                            <input
                                type="text"
                                id="userAdres"
                                name="adres"
                                placeholder="Vul uw straat in"
                                required
                                onChange={(e) => setUserStreet(e.target.value)}
                            />
                            <label>Huisnummer*</label>
                            <input
                                type="text"
                                id="userAdres"
                                name="userHousenumber"
                                placeholder="Vul uw huisnummer in"
                                required
                                onChange={(e) => setUserHousenumber(e.target.value)}
                            />
                            <label>Huisnummer toevoeging</label>
                            <input
                                type="text"
                                id="userHousenumberAddition"
                                name="housenumberaddition"
                                onChange={(e) => setUserHousenumberAddition(e.target.value)}
                            />

                            <label>Postcode*</label>
                            <input
                                type="text"
                                id="userPostcode"
                                name="postcode"
                                placeholder="Vul uw postcode in" 
                                required
                                onChange={(e) => setUserZipcode(e.target.value)}
                                 />

                            <label>Woonplaats*</label>
                            <input
                                type="text"
                                id="userWoonplaats"
                                name="woonplaats"
                                placeholder="Vul uw woonplaats in" 
                                required
                                onChange={(e) => setUserCity(e.target.value)}
                                />

                            <label>Telefoonnummer*</label>
                            <div className="telinput">
                                <PhoneInput
                                    placeholder="Vul uw telefoonnummer in"
                                    required
                                    defaultCountry="NL"
                                    value={userPhonenumber}
                                    onChange={(value) => setUserPhonenumber(value)}
                                    error={userPhonenumber ? (isPossiblePhoneNumber(userPhonenumber) ? undefined : 'Ongeldig telefoonnummer') : 'Dit veld is verplicht'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="">
                <div className="register-overledene">
                    <h2>Gegevens overledene</h2>
                    <div>
                        <div>
                            <label>Voornaam*</label>
                            <input
                                type="text"
                                id="voornaam"
                                name="voornaam"
                                placeholder="Voornaam van de overledene" 
                                required
                                onChange={(e) => setFirstname(e.target.value)}
                                 />
                        </div>
                        <div>
                            <label>Achternaam*</label>
                            <input
                                type="text"
                                id="achternaam"
                                name="achternaam"
                                placeholder="Achternaam van de overledene" 
                                required
                                onChange={(e) => setSurname(e.target.value)}
                                 />
                                </div>
                        <div>
                            <label>Straat*</label>
                            <input
                                type="text"
                                id="adres"
                                name="adres"
                                placeholder="Straat van de overledene"
                                required
                                onChange={(e) => setStreet(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>Huisnummer*</label>
                            <input
                                type="text"
                                id="housenumber"
                                name="housenumber"
                                placeholder="Huisnummer van de overledene"
                                required
                                onChange={(e) => setHousenumber(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>Huisnummer toevoeging</label>
                            <input
                                type="text"
                                id="housenumberaddition"
                                name="housenumberaddition"
                                onChange={(e) => setHousenumberAddition(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>Postcode*</label>
                            <input
                                type="text"
                                id="postcode"
                                name="postcode"
                                placeholder="Postcode van de overledene" 
                                required
                                onChange={(e) => setZipcode(e.target.value)}
                                 />

                        </div>
                        <div>
                            <label>Woonplaats*</label>
                            <input
                                type="text"
                                id="woonplaats"
                                name="woonplaats"
                                placeholder="Woonplaats van de overledene" 
                                required
                                onChange={(e) => setCity(e.target.value)}
                                 />
                        </div>
                    </div>
                </div>   	<div className="verplichte">* verplichte velden</div>

            </div>
            <div className="maakeenaccount">
                <h2>Maak een account aan</h2>
                <p>Een account kost eenmalig &euro; 89.50 en blijft na registratie 9 maanden actief, waardoor u op ieder moment organisaties kan aanvinken.</p>
                <div>
                        <div className="error-message">
                            <p
                                className="LoginError"
                                aria-live="assertive"
                            >
                                {errorMessage}
                            </p>
                            <div className="clear"></div>
                        </div>
                        <div className="confirm-message">
                            <p
                                className="user-management-confirm "
                                aria-live="assertive"
                            >
                                {confirmMessage}
                            </p>
                            <div className="clear"></div>
                        </div>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            placeholder="Email"
                            required
                            onChange={(e) => setUserEmail(e.target.value)}
                        />
                        <input
                            type="password"
                            id="password"
                            name="password"
                            placeholder="wachtwoord"
                            required
                            onChange={(e) => setUserPassword(e.target.value)}
                        />
                        <input
                            type="password"
                            id="password_repeat"
                            name="password_repeat"
                            placeholder="herhaal wachtwoord" 
                            required
                            onChange={(e) => setUserRepeatPassword(e.target.value)}
                        />
                </div>
                <div className="maakeenaccount2">
                    <p className="p2">Uw gegevens en dat van de overledene worden alleen gebruikt voor
                        het informeren van de door u aangevinkte organistaties.
                        Uw gegevens worden niet verkocht. Na het sluiten van uw account
                        worden uw gegevens verwijderd uit ons systeem.</p>
                </div>
                        <div className="maakeenaccount3">
                            <div className="field">
                                <input type="checkbox" name="terms_of_service" required onChange={(e) => setTermsOfService(e.target.value)} />
                                <span className="checkmark"></span>
                            </div>
                            <span className="p2">Ik ga akkoord met de 
                                <strong>
                                    <Link to="/algemene-voorwaarden"> algemene voorwaarden en de privacyverklaring.</Link>
                                </strong>
                            </span>
                </div>
                    <div className="maakeenaccount-submit"><input type="submit" value="Maak account" className="btn-maakacc" /></div>
            </div>

            <div>

                <div className="vordelendegitaal">
                    <h2>Voordelen Digitaal Meldpunt Overlijden</h2>
                    <li className="vordelendegitaal-list">Stopt snel alle doorlopende kosten na overlijden</li>
                    <li className="vordelendegitaal-list">Het bespaart u energie en tijd, &eacute;n geeft rust</li>
                    <li className="vordelendegitaal-list">Alles onder controle in &eacute;&eacute;n online overzicht</li>
                </div>
                <div className="docpen"></div>
            </div>
                </div>
            </form>
        </>
    );
}

export default RegisterContactPerson;