import baseService from "./BaseService";

// Get a category
const Get = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/Category/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// List all category
const List = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/Category/list', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Create a new category
const Create = (dto) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put('/Category/create', dto)
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Delete a category
const Delete = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .delete(`/Category/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const Update = (dto) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put('/Category/update', dto)
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const CategoryService = {
    Get,
    List,
    Create,
    Delete,
    Update
};

export default CategoryService;