import React from "react";
import { useState, useEffect } from 'react';

import AgencyService from "../../../Service/AgencyService";
import UserFileAgencyService from "../../../Service/UserFileAgencyService";
import DashboardOrganizationsLeft from '../DashboardOrganizationsLeft/DashboardOrganizationsLeft';
import DashboardOrganizationsRight from '../DashboardOrganizationsRight/DashboardOrganizationsRight';

function DashboardOrganizations()
{
    const [linkedAgencyList, setLinkedAgencyList] = useState(null);
    const [filter, setFilter] = useState("");

    // Retrieve items already activated
    const RefreshUserFileAgencies = () =>
    {
        UserFileAgencyService.GetAll().then((res) =>
        {
            setLinkedAgencyList(res.data);
        });
    };
    useEffect(() =>
    {
        RefreshUserFileAgencies();
    }, []);

    const FilterOrganisation = (e) =>
    {
        setFilter(e.target.value.toLowerCase());
    };

    return (
        <>
            <div className="dashboard-selecteer-input">
                <input type="text" placeholder="welke organisatie zoekt u?" onChange={FilterOrganisation} />
            </div>
            <div className="main-telephone">
                <DashboardOrganizationsLeft RefreshData={RefreshUserFileAgencies} AgencyList={linkedAgencyList} AgencyFilter={filter} />
                <DashboardOrganizationsRight RefreshData={RefreshUserFileAgencies} AgencyList={linkedAgencyList} />
            </div>	
        </>
    );
}

export default DashboardOrganizations;