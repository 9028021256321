import baseService from "./BaseService";

const Get = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/Partner/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const List = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/Partner/list', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const Create = (dto) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put('/Partner/create', dto)
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const Delete = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .delete(`/Partner/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const Update = (dto) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put('/Partner/update', dto)
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const GetLogoForHash = (hash) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/Partner/logo-by-hash/${hash}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const GetLogoForUserfile = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/Partner/logo-for-userfile', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const GetLogo = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/Partner/logo', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const PartnerService = {
    Get,
    List,
    Create,
    Delete,
    Update,
    GetLogoForHash,
    GetLogoForUserfile,
    GetLogo
};

export default PartnerService;