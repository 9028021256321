import React from "react";
import { useState, useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import StatusService from "../../../Service/StatusService";
import './DashboardStatus.scss';

function DashboardStatus()
{
	const remarkLength = 40;
	const [statusList, setStatusList] = useState(null);
	const [showDialog, setShowDialog] = useState(false);
	const [dialogHeaderText, setDialogHeaderText] = useState("");
	const [dialogText, setDialogText] = useState("");

	// Retrieve status
	useEffect(() =>
	{
		StatusService.Get().then((res) =>
		{
			setStatusList(res.data);
		});
	}, []);


	// Open evidence
	const OpenEvidence = (e) =>
	{
		// Lookup item
		const id = e.target.getAttribute("data-status-id");

		// Request file
		StatusService.Evidence(id).then((res) =>
		{
			// Create a new blob object
			const objUrl = window.URL.createObjectURL(new Blob([res.data]));
			const link = document.createElement('a');
			link.href = objUrl;
			link.target = "_blank";
			link.setAttribute('download', 'Bewijsstuk.pdf');
			document.body.appendChild(link);
			link.click();
		});
	};


	const ShowRemarks = (e) =>
	{
		// Lookup item
		const id = e.target.getAttribute("data-status-id");
		const status = statusList.find((item) => item.id == id);

		if (status !== undefined)
		{
			// Set dialog text
			setDialogHeaderText("Openstaande acties: " + status.agency_name);
			setDialogText(status.remarks);
			// Show dialog
			setShowDialog(true);
		}
	};

	// If we're loading or we don't have any status items
	if (statusList === null || statusList.length === 0)
		return "";

	return (
		<section>
			<h1>Bent u een organisatie vergeten?</h1>
			<p className="p2">Uw account is 9 maanden actief, waardoor u ook achteraf nog organisaties kan aanvinken.</p>


			<div className="dashboard-statusoverzicht">Statusoverzicht</div>
			<div className="table-container">
			<table className="wrapper-statusoverzichtbox">
				<tbody className="dashboard-status">
					<tr className="wrapper-statusoverzicht">
						<td>Organisatie</td>
						<td>Geinformeerd
							<span className="tooltip">
								<img src="/images/icon-questionmark.svg" className="middle" />
								<span className="tooltiptext">
									Hier ziet u de door ons geinformeerde instanties.
								</span>
							</span>
						</td>
						<td>Bevestiging
							<span className="tooltip">
								<img src="/images/icon-questionmark.svg" className="middle" />
								<span className="tooltiptext">
									Hier ziet u de instanties die een ontvangstbevestiging hebben afgegeven.
									Dit is dus nog niet de bevestiging van de opzegging of wijziging van het contact.
									<br /><br />
									Sommige instanties zenden geen ontvangstbevestiging,
									of zij zenden wel een ontvangstbevestiging,
									maar deze heeft onvoldoende informatie,
									zodat Nazorgdesk niet weet om wie het gaat.
								</span>
							</span>
						</td>
						<td>Bewijsstuk
							<span className="tooltip">
								<img src="/images/icon-questionmark.svg" className="middle" />
								<span className="tooltiptext">
									Hier vindt u het bewijs van de aangevraagde wijziging.
								</span>
							</span>
						</td>
						<td>Geen reactie
							<span className="tooltip">
								<img src="/images/icon-questionmark.svg" className="middle" />
								<span className="tooltiptext">
									Nazorgdesk doet haar uiterste best een bevestiging van de instantie te verkrijgen.
									Zolang een reactie uitblijft zal er meerdere keren een herinnering worden gezonden naar de instantie.
								</span>
							</span>							
						</td>
						<td>Niet gevonden
							<span className="tooltip">
								<img src="/images/icon-questionmark.svg" className="middle" />
								<span className="tooltiptext">
									Soms komt het voor dat een instantie de overledenen niet kan vinden in hun systeem.
									<br /><br />
									Ga in dit geval na of u niet een andere instantie had bedoeld.
									U kunt natuurlijk weer opnieuw een instantie aanvinken.
									Wij gaan dan weer voor u aan de slag.
								</span>
							</span>
						</td>
						<td>Openstaande acties
							<span className="tooltip">
								<img src="/images/icon-questionmark.svg" className="middle" />
								<span className="tooltiptext">
									Er zijn soms bepaalde dingen die Nazorgdesk niet voor u kan uitvoeren.
									<br /><br />
									Dit kan zo zijn, omdat het niet binnen de door u afgegeven volmacht is opgenomen, maar soms ook om praktische redenen.
									<br /><br />
									Nazorgdesk geeft in zo'n geval aan welke acties u zelf nog moet ondernemen.
								</span>
							</span>
						</td>
					</tr>
					{statusList.map((status, statusIndex) => (
						<tr className="wrapper-statusoverzicht-detail" key={statusIndex}>
							<td>{status.agency_name}</td>
							<td className="aligncenter">{status.informed && (<img src="/images/icon-check.svg" className="checkIcon" />)}</td>
							<td className="aligncenter">{status.confirmed && (<img src="/images/icon-check.svg" className="checkIcon" />)}</td>
							<td className="aligncenter">{status.evidence.filename != null && (<img src="/images/icon-pdf.svg" className="middle" onClick={OpenEvidence} data-status-id={status.id} className="downloadIcon" />)}</td>
							<td className="aligncenter">{status.no_response && (<img src="/images/icon-check.svg" className="checkIcon" />)}</td>
							<td className="aligncenter">{status.not_found && (<img src="/images/icon-check.svg" className="checkIcon" />)}</td>
							<td>{status.remarks?.length > remarkLength ? (<>{status.remarks.substring(0, remarkLength)}... <a className="leesmeer" data-status-id={status.id} onClick={ShowRemarks} title="Lees meer">Lees meer</a></>) : status.remarks}</td>
						</tr>
					))}
					<Dialog header={dialogHeaderText} visible={showDialog} onHide={() => setShowDialog(false)}
						style={{ width: '75vw' }}>
						<p>{dialogText}</p>
					</Dialog>
					</tbody>
				</table>
			</div>
		</section>	
	);
}

export default DashboardStatus;
