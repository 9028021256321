import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import AuthService from "../../Service/AuthService";
import PermissionService from "../../Service/PermissionService";
import PartnerService from "../../Service/PartnerService";
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import ErrorHandler from '../ErrorHandler';
import { passwordStrength } from 'check-password-strength';
import Countdown, { zeroPad } from 'react-countdown';


import './Header.scss';
import UserService from '../../Service/UserService';

function Header()
{
    const toasthdr = useRef(null);
    const navigate = useNavigate();
    const [dto, setDto] = useState(null);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [userDto, setUserDto] = useState({});
    const [passwordRepeatShown, setPasswordRepeatShown] = useState(false);
    const togglePasswordRepeat = () => { setPasswordRepeatShown(!passwordRepeatShown); }
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => { setPasswordShown(!passwordShown); }
    const expireDate = Date.now() + (30 * 60 * 1000); // 30 min in milliseconden
    const [key, setKey] = useState(0);

    // Get partnerHash from URL (used on logon page)
    const { partnerHash } = useParams();

    useEffect(() =>
    {
        // We have a partner hash, probably located on the login page
        if (partnerHash !== undefined)
        {
            // Lookup partner to retrieve logo
            PartnerService.GetLogoForHash(partnerHash).then((res) =>
            {
                setDto(res.data);
            });
        }
        else if (PermissionService.hasPermission('User'))
        {
            // Check if current userfile is linked to a partner
            if (sessionStorage.getItem("partnerLogo") !== null)
            {
                setDto(JSON.parse(sessionStorage.getItem("partnerLogo")));
            }
            else
            {
                PartnerService.GetLogoForUserfile().then((res) =>
                {
                    sessionStorage.setItem("partnerLogo", JSON.stringify(res.data));
                    setDto(res.data);
                });
            }
        }
        else if (PermissionService.hasPermission('Partner'))
        {
            // Check if current userfile is linked to a partner
            if (sessionStorage.getItem("partnerLogo") !== null)
            {
                setDto(JSON.parse(sessionStorage.getItem("partnerLogo")));
            }
            else
            {
                PartnerService.GetLogo().then((res) =>
                {
                    sessionStorage.setItem("partnerLogo", JSON.stringify(res.data));
                    setDto(res.data);
                });
            }
        }
    }, []);

    const logout = (e) =>
    {
        if (AuthService.getIsAdopted())
        {
            // set orig EstrategyAdmin user
            var origAuth = AuthService.getOrigUserAuth()
            AuthService.setAuth(origAuth);
            AuthService.setCurrentUsername(origAuth.username);
            e.preventDefault();
            navigate("/AdoptUser");
            AuthService.clearAdopted();
        }
        else 
        {
            AuthService.logout();
            navigate('/');
        }
    };

    const ChangeDetails = (e) =>
    {
        // Get current data
        UserService.Get(AuthService.getAuth().user_id).then((res) =>
        {
            // Store data
            setUserDto(res.data);

            // Show dialog to edit own data
            setShowEditDialog(true);
        });
    };

    const HideDetailDialog = () =>
    {
        setUserDto({});
        setShowEditDialog(false);
    };

    const SaveData = () =>
    {
        // Check password, if filled we need to validate and post it
        if (userDto.password !== undefined && userDto.password !== null && userDto.password !== "")
        {
            // Check if both passwords equal
            if (userDto.password !== userDto.passwordRepeat)
            {
                ErrorHandler.ToastErrorMessage(toasthdr, 'De opgegeven wachtwoorden komen niet overeen');

                return;
            }

            // Check password complexity
            if (passwordStrength(userDto.password).value === 'Weak' || passwordStrength(userDto.password).value === 'Too weak')
            {
                ErrorHandler.ToastErrorMessage(toasthdr, ErrorHandler.PasswordComplexityText());
                return;
            }
        }

        // Store data
        UserService.Update(userDto).then((res) =>
        {
            toasthdr.current.show({ severity: 'success', summary: 'Opgeslagen', detail: 'De gegevens zijn opgeslagen' });
            HideDetailDialog();
        })
        .catch((err) => 
        {
            ErrorHandler.ToastErrors(toasthdr, 'Er zijn een of meerdere fouten opgetreden bij het opslaan: ', err);
        });
    };

    const resetTimer = () =>
    {
        setKey(Date.now());
    }

    useEffect(() =>
    {
        window.addEventListener("click", resetTimer);
        window.addEventListener("keypress", resetTimer);
    }, []);

    const renderer = ({ minutes, seconds, completed }) =>
    {
        if (completed)
        {
            logout();
        }
        return <></>
    };

    return (
        <header>
            <Toast ref={toasthdr} />
            <nav>
                <div className="WrapperInner">
                    <div id="header">
                        <div className="header-logo">
                            <Link to="/">
                                <img src="/images/logo.jpg" title="logo" alt="Nazorgdesk logo" />
                            </Link>
                        </div>
                        <div className="header-logo-partner">
                            {dto !== null && dto.file !== undefined && dto.file !== null ?
                                <>Deze dienst wordt u aangeboden door: <br /><img src={`data:${dto.mimetype};base64,${dto.file}`} alt="Logo Partner" /></> :
                                <></>
                            }
                        </div>
                        <div className="header-uitloggen">
                            {AuthService.getAuth() != null ?
                                <>
                                    <a className="header-logoutlink" onClick={logout}>
                                        <div className="header-uitloggen-wrapper">
                                            <span id="Header_btnLogout" onClick={logout}>Uitloggen</span>
                                        </div>
                                    </a>
                                    <a className="header-logoutlink" onClick={ChangeDetails}>
                                        <div className="header-changedetails-wrapper">
                                            <span id="Header_btnLogout" onClick={ChangeDetails}>Gegevens wijzigen</span>
                                        </div>
                                    </a>
                                    <Countdown date={expireDate} renderer={renderer} key={key} visible={false} />
                                </>
                                :
                                <Link className="header-logoutlink" to='/login'>
                                    <div className="header-uitloggen-wrapper">
                                        <span id="Header_btnLogout" onClick={logout}>Inloggen</span>
                                    </div>
                                </Link>
                            }
                        </div>
                    </div>
                </div>
            </nav>
            <div className="headerdevide"></div>
            <Dialog header="Gegevens bewerken" visible={showEditDialog} onHide={HideDetailDialog}
                style={{ width: '75vw' }}>
                <div className="fields">
                    <div className="field">
                        <label htmlFor="txtFirstname">Voornaam:</label>
                        <input
                            id="txtFirstname"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            defaultValue={userDto.firstname}
                            onChange={(e) => setUserDto({ ...userDto, firstname: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtSurname">Achternaam:</label>
                        <input
                            id="txtSurname"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            defaultValue={userDto.surname}
                            onChange={(e) => setUserDto({ ...userDto, surname: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtStreet">Straat:</label>
                        <input
                            id="txtStreet"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            defaultValue={userDto.street}
                            onChange={(e) => setUserDto({ ...userDto, street: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtHousenumber">Huisnummer:</label>
                        <input
                            id="txtHousenumber"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            defaultValue={userDto.housenumber}
                            onChange={(e) => setUserDto({ ...userDto, housenumber: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtHousenumberAddition">Huisnummer toevoeging:</label>
                        <input
                            id="txtHousenumberAddition"
                            type="text"
                            defaultValue={userDto.housenumberaddition}
                            onChange={(e) => setUserDto({ ...userDto, housenumberaddition: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtZipcode">Postcode:</label>
                        <input
                            id="txtZipcode"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            defaultValue={userDto.zipcode}
                            onChange={(e) => setUserDto({ ...userDto, zipcode: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtCity">Plaats:</label>
                        <input
                            id="txtCity"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            defaultValue={userDto.city}
                            onChange={(e) => setUserDto({ ...userDto, city: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtPhonenumber">Telefoonnummer:</label>
                        <input
                            id="txtPhonenumber"
                            type="text"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            defaultValue={userDto.phonenumber}
                            onChange={(e) => setUserDto({ ...userDto, phonenumber: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtEmail">Email:</label>
                        <input
                            id="txtEmail"
                            type="email"
                            required={true}
                            onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                            onInput={e => e.target.setCustomValidity("")}
                            title="Dit veld is verplicht"
                            defaultValue={userDto.email}
                            onChange={(e) => setUserDto({ ...userDto, email: e.target.value })}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="txtPasssword">Wachtwoord:</label>
                        <div className="password-field-holder">
                            <input
                                id="txtPasssword"
                                type={passwordShown ? "text" : "password"}
                                onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                                onInput={e => e.target.setCustomValidity("")}
                                onChange={(e) => setUserDto({ ...userDto, password: e.target.value })}
                            />
                            <span className="password-toggle" onClick={togglePassword}>Toon</span>
                        </div>
                    </div>
                    <div className="field">
                        <label htmlFor="txtPassswordRepeat">Herhaal wachtwoord:</label>
                        <div className="password-field-holder">
                            <input
                                id="txtPassswordRepeat"
                                type={passwordRepeatShown ? "text" : "password"}
                                onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                                onInput={e => e.target.setCustomValidity("")}
                                onChange={(e) => setUserDto({ ...userDto, passwordRepeat: e.target.value })}
                            />
                            <span className="password-toggle" onClick={togglePasswordRepeat}>Toon</span>
                        </div>
                    </div>
                </div>
                <div className="buttons">
                    <input type="submit" name="" value="Opslaan" onClick={SaveData} />
                    <input type="submit" name="" value="Annuleren" onClick={HideDetailDialog} />
                </div>
            </Dialog>
        </header>
    );
}

export default Header;