import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import ErrorHandler from '../../ErrorHandler';
import CategoryService from '../../../Service/CategoryService'

function Item()
{
    const toast = useRef(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const [title, setTitle] = useState(null);
    const [dto, setDto] = useState(null);

    useEffect(() =>
    {
        if (id === undefined)
        {
            // New item
            setTitle("Toevoegen");
            setDto({});
        }
        else
        {
            setTitle("Bewerken");
            CategoryService.Get(id).then((res) =>
            {
                setDto(res.data);
            });
        }
    }, []);

    // Save the current dto
    const saveDto = async (e) =>
    {
        // Prevent default submit behaviour
        e.preventDefault();

        let saveCallback = null;
        if (dto.id === null || dto.id === undefined)
            saveCallback = CategoryService.Create(dto);
        else
            saveCallback = CategoryService.Update(dto);

        saveCallback.then((res) =>
        {
            toast.current.show();
            navigate('..', { state: { toastParams: { severity: 'success', summary: 'Opgeslagen', detail: 'De categorie is opgeslagen' } }});
        })
        .catch((err) =>
        {
            ErrorHandler.ToastErrors(toast, 'Er zijn een of meerdere fouten opgetreden bij het opslaan: ', err);
        });
    };

    if (title === null || dto === null)
        return (<div className="editContainer">Laden...</div>);

    return (
        <div className="editContainer">
            <Toast ref={toast} />
            <h1>{title}</h1>
            <form onSubmit={saveDto} autoComplete="off">
                <div className="fields">
                        <div className="field">
                            <label htmlFor="">Naam:</label>
                            <input
                                type="text"
                                required={true}
                                onInvalid={e => e.target.setCustomValidity("Dit veld is verplicht")}
                                onInput={e => e.target.setCustomValidity("")}
                                title="Dit veld is verplicht"
                                defaultValue={dto.name}
                                onChange={(e) => setDto({ ...dto, name: e.target.value })}
                            />
                        </div>
                </div>
                <div className="buttons">
                    <input type="submit" value="Opslaan" />
                    <Link to='..'>
                        <input type="submit" value="Annuleren" />
                    </Link>
                </div>
            </form>
        </div>
    );
}

export default Item;