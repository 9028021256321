import PermissionService from "./PermissionService";

const AuthDTO = "auth";

// get the session storage
const getAuthStorage = () =>
{
    return sessionStorage.getItem(AuthDTO);
};

// get the access token
const getLocalAccessToken = () =>
{
    const auth = JSON.parse(getAuthStorage());
    return auth?.accesstoken;
};

// get the refresh token out of the session storage
const getLocalRefreshToken = () =>
{
    const auth = JSON.parse(getAuthStorage());
    return auth?.refreshtoken;
};

// update the access token
const updateNewAccessToken = (token) =>
{
    setAuth(token);
};

// set the authentication
const setAuth = (auth) =>
{
    sessionStorage.setItem(AuthDTO, JSON.stringify(auth));
    setCurrentUsername(auth.username);
};
// get the authentication
const getAuth = () =>
{
    const auth = JSON.parse(getAuthStorage());
    return auth;
};

// save current username
const setCurrentUsername = (username) =>
{
    sessionStorage.setItem("username", username);
};

// get current username
const getCurrentUsername = () =>
{
    return sessionStorage.getItem("username");
};

const setOrigUserAuth = (auth) =>
{
    sessionStorage.setItem("OrigUserAuth", JSON.stringify(auth));
};
const getOrigUserAuth = () =>
{
    return JSON.parse(sessionStorage.getItem("OrigUserAuth"));
};
const getIsAdopted = () =>
{
    return sessionStorage.getItem("IsAdopted") == "1";
};
// save user is adopted
const setIsAdopted = (adopted) =>
{
    sessionStorage.setItem("IsAdopted", adopted ? "1" : "0");
};

const clearAdopted = () =>
{
    sessionStorage.setItem("IsAdopted", "0");
    sessionStorage.setItem("OrigUserAuth", "");
};

const setPermissions = (permissions) =>
{
    if (permissions == null)
        sessionStorage.setItem("UserPermissions", null);
    else
        sessionStorage.setItem("UserPermissions", JSON.stringify(permissions));
};

const getPermissions = () =>
{
    return new Promise((resolve, reject) =>
    {
        let permissions = sessionStorage.getItem("UserPermissions");
        if (permissions == null)
        {
            PermissionService.getPermissionListForUser().then((res) =>
            {
                if (res?.status === 200)
                {
                    AuthService.setPermissions(res.data);
                    resolve(res.data);
                }
                else
                    setErrorMessage(schoolRes?.status);
            }).catch((error) =>
            {
                reject(error);
            });
        }
        else
            resolve(JSON.parse(permissions));
    });
};

const logout = () =>
{
    sessionStorage.clear();
};


const AuthService = {
    getAuthStorage,
    getLocalAccessToken,
    getLocalRefreshToken,
    updateNewAccessToken,
    getAuth,
    setAuth,
    setCurrentUsername,
    getCurrentUsername,
    setIsAdopted,
    getIsAdopted,
    clearAdopted,
    setOrigUserAuth,
    getOrigUserAuth,
    setPermissions,
    getPermissions,
    logout
};

export default AuthService;
