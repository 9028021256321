import baseService from "../Service/BaseService";
import AuthService from "./AuthService";

// Constants
const UserFileStatusKey = "userfilestatus";

const Get = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/UserFile/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const List = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/UserFile/list', {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const Create = (dto) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put('/UserFile/create', dto)
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const Delete = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .delete(`/UserFile/${id}`, {})
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const Update = (dto) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put('/UserFile/update', dto)
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

// Create a user file 
const CreateUserFileWithAgencies = (
    firstname,
    surname,
    street,
    housenumber,
    housenumberAddition,
    zipcode,
    city,
    userFirstname,
    userSurname,
    userStreet,
    userHousenumber,
    userHousenumberAddition,
    userZipcode,
    userCity,
    userPhonenumber,
    userEmail,
    userPassword,
    agencies
) =>
{
    return new Promise((resolve, reject) =>
    {
        let dto = {
            "firstname": firstname,
            "surname": surname,
            "street": street,
            "housenumber": housenumber,
            "housenumberaddition": housenumberAddition,
            "zipcode": zipcode,
            "city": city,
            "user": {
                "firstname": userFirstname,
                "surname": userSurname,
                "street": userStreet,
                "housenumber": userHousenumber,
                "housenumberaddition": userHousenumberAddition,
                "zipcode": userZipcode,
                "city": userCity,
                "phonenumber": userPhonenumber,
                "email": userEmail,
                "password": userPassword,
            }
        };
        if (agencies.length > 0)
            dto.agencies = agencies;

        baseService
            .put('/UserFile/create', dto, { })
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const PollPayment = (userFileId) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/UserFile/poll/${userFileId}`, { })
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const getStatusStorage = () =>
{
    return sessionStorage.getItem(UserFileStatusKey);
};

const setStatusStorage = (statusDTO) =>
{
    sessionStorage.setItem(UserFileStatusKey, JSON.stringify(statusDTO));
};

const RefreshStatus = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/UserFile/status', {})
            .then((response) =>
            {
                setStatusStorage(response.data);
                resolve();
            })
            .catch(() =>
            {
                setStatusStorage(null);
                reject();
            });
    });
};

const GetStatus = () =>
{
    const storage = getStatusStorage();
    if (storage === null || storage === undefined)
        return null;

    return JSON.parse(storage);
};

const Process = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .post('/UserFile/process', { })
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const CurrentDeathDertificate = () =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get('/UserFile/certificate',
                {
                    headers: {
                        Accept: 'application/octet-stream'
                    },
                    responseType: 'blob'
                }
            )
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const DeathDertificate = (id) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .get(`/UserFile/certificate/${id}`,
                {
                    headers: {
                        Accept: 'application/octet-stream'
                    },
                    responseType: 'blob'
                }
            )
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const UploadDeathCertificate = (dto) =>
{
    return new Promise((resolve, reject) =>
    {
        baseService
            .put('/UserFile/upload-certificate', dto)
            .then((response) =>
            {
                resolve(response);
            })
            .catch((error) => reject(error));
    });
};

const UserFileService = {
    Get,
    List,
    Create,
    Delete,
    Update,
    CreateUserFileWithAgencies,
    PollPayment,
    RefreshStatus,
    GetStatus,
    Process,
    CurrentDeathDertificate,
    DeathDertificate,
    UploadDeathCertificate
};

export default UserFileService;