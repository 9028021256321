import React, { useRef } from "react";
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from 'primereact/dialog';
import { Toast } from 'primereact/toast';
import useCollapse from 'react-collapsed';
import AgencyService from "../../../Service/AgencyService";
import UserFileAgencyService from "../../../Service/UserFileAgencyService";
// Styling for compoment
import './DashboardOrganizationsLeft.scss';

function DashboardOrganizationsLeft(props)
{
	// Declare all statelists
	const [agencyList, setAgencyList] = useState(null);
	const [fullAgencyList, setFullAgencyList] = useState(null);
	const [categoryList, setCategoryList] = useState(null);
	const [showRequestAgencyForm, setShowRequestAgencyForm] = useState(false);
	const [agencyRequestName, setAgencyRequestName] = useState("");
	const [agencyRequestMessage, setAgencyRequestMessage] = useState("");
	const [expandedState, setExpandedState] = useState(null);

	const toast = useRef(null);

	// Retrieve full agency list once
	useEffect(() =>
	{
		AgencyService.getActiveAgencyList().then((res) =>
		{
			setFullAgencyList(res.data);
		});

		// Initial, set expanded state
		if (expandedState === null)
		{
			setExpandedState([]);
		}
	}, []);

	// filter agency list
	useEffect(() =>
	{
		if (fullAgencyList !== null)
		{
			let filteredList = fullAgencyList;
			if (props.AgencyFilter !== undefined && props.AgencyFilter !== "")
				filteredList = fullAgencyList.filter((item) => item.name.toLowerCase().includes(props.AgencyFilter));

			// Store filtered list
			setAgencyList(filteredList);

			// Get unqiue categories from filtered agency list
			setCategoryList([...new Set(filteredList.map(item => item.category))]);
		}
	}, [fullAgencyList, props.AgencyFilter]);

	// Confirm dialog for deleting link between userfile and agency
	const confirmUncheck = (accept, reject) =>
	{
		confirmDialog({
			message: 'Weet je zeker dat je deze instantie wilt uitvinken? Ingevoerde gegevens zullen hiermee verloren gaan.', 
			header: 'Bevestiging',
			icon: 'pi pi-exclamation-triangle',
			acceptLabel: 'Ja', 
			rejectLabel: 'Nee',
			accept,
			reject
		});
	};

	// Event after (un)checking agency
	const handleAgencyChange = (e) =>
	{
		// Check if the event origins from a checkbox
		if (e.target.type === 'checkbox')
		{
			if (e.target.checked)
			{
				// Create userfile agency link
				UserFileAgencyService.Create(e.target.value).then((res) =>
				{
					// And refresh
					props.RefreshData();
				});

			}
			else
			{
				// Lookup item
				let unlinkItem = GetSelectedAgency(e.target.value);

				if (unlinkItem !== undefined)
				{
					// Item is locked (already processed to status), we cannot uncheck it
					if (unlinkItem.locked)
					{
						// Activate checkbox again, do not send API calls
						e.target.checked = true;
						return;
					}

					// Check if we have form information for this agency
					if (unlinkItem.fields.length > 0 && unlinkItem.fields.find(field => field.value !== null && field.value !== "") !== undefined)
					{
						confirmUncheck(
							() =>
							{
								// Delete userfile agency link
								UserFileAgencyService.Delete(unlinkItem.id).then((res) =>
								{
									// And refresh
									props.RefreshData();
								});
							},
							() =>
							{
								// Activate checkbox again, do not send API calls
								e.target.checked = true;
							}
						);
					}
					else
					{
						// Nothing filled in yet, delete userfile agency link
						UserFileAgencyService.Delete(unlinkItem.id).then((res) =>
						{
							// And refresh
							props.RefreshData();
						});
					}
				}
			}
		}
	};

	const GetSelectedAgency = (agency_id) =>
	{
		return props.AgencyList.find(item => item.agency_id == agency_id);
	};

	const IsAgencySelected = (agency_id) =>
	{
		return GetSelectedAgency(agency_id) !== undefined;
	};

	const ClearAndCloseRequestAgency = () => 
	{
		// Empty fields
		setAgencyRequestName("");
		setAgencyRequestMessage("");

		// Close popup
		setShowRequestAgencyForm(false)
	};

	const PostRequestAgency = () =>
	{
		AgencyService.RequestAgency({
			name: agencyRequestName,
			message: agencyRequestMessage,
		}).then((res) =>
		{
			// Toast success
			toast.current.show({ severity: 'success', summary: 'Verstuurd', detail: 'De aanvraag is verzonden.' });

			// Clear and close popup
			ClearAndCloseRequestAgency();
		});
	};


	if (agencyList === null || categoryList === null || props === undefined || props.AgencyList === null)
		return (
			<div className="col-left">
				Laden...
			</div>
		);

	function SectionCollapse(props)
	{
		const config = {
			defaultExpanded: props.defaultExpanded || expandedState.find((item)=>item.idx == props.idx)?.state || false,
			collapsedHeight: props.collapsedHeight || 0
		};
		const { getCollapseProps, getToggleProps, isExpanded } = useCollapse(config);
		return (
			<div className="collapsible">
				<div className="header" {...getToggleProps({
					onClick: () =>
					{
						const item = expandedState.find((item) => item.idx == props.idx);
						if (item !== undefined)
							item.state = !item.state;
						else
							expandedState.push({ idx: props.idx, state: true });
					}
				})}>
					<div className="title">{props.title}</div>
					<div className="arrow">
						<img src={"/images/" + (isExpanded ? "arrow-up.png" : "arrow-down.png")}  />
					</div>
				</div>
				<div {...getCollapseProps()}>
					<div className="content">
						{props.children}
					</div>
				</div>
			</div>
		);
	}

	return (
		<>
			<Toast ref={toast} />
			<ConfirmDialog />
			<div className="col-left">
				{categoryList.map((category, catindex) => (
					<SectionCollapse className="dashboard-tv collapsible" title={category} key={catindex} idx={catindex}>

						<div className="dashboard-groep">
							{agencyList.map((agency, index) =>
							{
								if (agency.category == category)
								{
									return (
										<div className="checkbox-outer" key={index}>
											<input type="checkbox" name="agencies" value={agency.id}
												data-category={agency.agencycategory} onChange={handleAgencyChange}
												id={`cb${agency.id}`} checked={IsAgencySelected(agency.id)} />
											<label htmlFor={`cb${agency.id}`} >{agency.name}</label>
										</div>
									);
								}
								else
									return "";
							})}
						</div>
					</SectionCollapse>
				))}
				<div className="clearboth"></div>

				<button className="btn-dashboard" onClick={() => setShowRequestAgencyForm(true)}>Organisatie niet gevonden? Klik hier</button>
				<Dialog header="Organisatie toevoegen" className="dashboard-add-organisation-dialog" style={{ width: '75vw' }}
					visible={showRequestAgencyForm} onHide={() => ClearAndCloseRequestAgency()}>
					<div className="dashboard-add-organisation-field-block">
						<div className="selectbox-label"><label htmlFor="txtAgencyRequestName">Naam</label></div>
						<div className="inputtext">
							<input id="txtAgencyRequestName" type="text" defaultValue={agencyRequestName} onInput={e => setAgencyRequestName(e.target.value)} />
						</div>
					</div>
					<div className="dashboard-add-organisation-field-block">
						<div className="selectbox-label"><label htmlFor="txtAgencyRequestText">Omschrijving</label></div>
						<div className="inputtext">
							<textarea id="txtAgencyRequestText" defaultValue={agencyRequestMessage} onInput={e => setAgencyRequestMessage(e.target.value) } />
						</div>
					</div>
					<div className="dashboard-add-organisation-button-holder">
						<input type="button" className="dashboard-add-organisation-button" onClick={ClearAndCloseRequestAgency} value="Annuleren" />
						<input type="button" className="dashboard-add-organisation-button" onClick={PostRequestAgency} value="Versturen" />
					</div>
				</Dialog>
			</div>
		</>
	);
}

export default DashboardOrganizationsLeft;
