import axios from "axios";
import AuthService from "../Service/AuthService";

const getBaseUrl = () =>
{
    let url;
    switch (process.env.NODE_ENV)
    {
        case 'production':
            // TODO: Replace with current location + /api?
            url = 'https://portal.nazorgdesk.nl/api';
            break;
        case 'development':
        default:
            url = 'https://nazorgdeskapi.estrategylocal.nl/';
    }

    return url;
};

const baseService = axios.create({
    baseURL: getBaseUrl(),
    headers: {
        "Content-Type": "application/json",
        accept: "application/json",
        "x-api-version": "1.0",
    }
});

baseService.interceptors.request.use(
    (config) =>
    {
        const token = AuthService.getLocalAccessToken();
        if (token)
        {
            config.headers["Authorization"] = `Bearer ${AuthService.getLocalAccessToken()}`;
        }
        return config;
    },
    (error) =>
    {
        return Promise.reject(error);
    }
);

baseService.interceptors.response.use(
    (res) =>
    {
        return res;
    },
    async (err) =>
    {
        const originalConfig = err.config;
        if (err.response)
        {
            // access token expired
            if (err.response.status === 401 && !originalConfig._retry)
            {
                // handle infinite loop
                originalConfig._retry = true;

                try
                {
                    const rs = await baseService.post('/User/get-accesstoken', {
                        refreshToken: AuthService.getLocalRefreshToken(),
                    })
                    AuthService.updateNewAccessToken(rs.data);

                    return baseService(originalConfig);
                } catch (_error)
                {
                    return Promise.reject(_error);
                }
            }
        }
        return Promise.reject(err);
    }
);

export default baseService;